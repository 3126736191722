@charset "UTF-8";
@import url("https://webfontworld.github.io/gonggames/EsaManru.css");
@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");
* {
  margin: 0;
  padding: 0;
  font-family: "NanumSquare", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --vh: 100%;
}

html {
  font-size: 16px;
}
html * {
  font-family: "NanumSquare", sans-serif;
  white-space: normal;
}

body {
  font-family: "NanumSquare", sans-serif;
  min-height: var(--vh);
}

h1 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border: 0;
}
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a,
button {
  cursor: pointer;
}

button {
  padding: 0;
}

ul,
ol {
  padding-left: 0;
  list-style: none;
}

address {
  font-style: normal;
}

table {
  width: 100%;
}

i {
  font-style: normal;
}

.red {
  color: #ff2222 !important;
}

.blue {
  color: #197edc !important;
}

.green {
  color: #19c791 !important;
}

.fs_0 {
  font-size: 0px !important;
}

.fs_4 {
  font-size: 4px !important;
}

.fs_8 {
  font-size: 8px !important;
}

.fs_12 {
  font-size: 12px !important;
}

.fs_16 {
  font-size: 16px !important;
}

.fs_20 {
  font-size: 20px !important;
}

.fs_24 {
  font-size: 24px !important;
}

.fs_28 {
  font-size: 28px !important;
}

.fs_32 {
  font-size: 32px !important;
}

.fs_36 {
  font-size: 36px !important;
}

.fs_40 {
  font-size: 40px !important;
}

.fs_44 {
  font-size: 44px !important;
}

.fs_48 {
  font-size: 48px !important;
}

.fs_52 {
  font-size: 52px !important;
}

.fs_56 {
  font-size: 56px !important;
}

.fs_60 {
  font-size: 60px !important;
}

.fs_64 {
  font-size: 64px !important;
}

.fs_68 {
  font-size: 68px !important;
}

.fs_72 {
  font-size: 72px !important;
}

.fs_76 {
  font-size: 76px !important;
}

.fs_80 {
  font-size: 80px !important;
}

.pt_0 {
  padding-top: 0px !important;
}

.pr_0 {
  padding-right: 0px !important;
}

.pl_0 {
  padding-left: 0px !important;
}

.pb_0 {
  padding-bottom: 0px !important;
}

.mt_0 {
  margin-top: 0px !important;
}

.mr_0 {
  margin-right: 0px !important;
}

.ml_0 {
  margin-left: 0px !important;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.pt_4 {
  padding-top: 4px !important;
}

.pr_4 {
  padding-right: 4px !important;
}

.pl_4 {
  padding-left: 4px !important;
}

.pb_4 {
  padding-bottom: 4px !important;
}

.mt_4 {
  margin-top: 4px !important;
}

.mr_4 {
  margin-right: 4px !important;
}

.ml_4 {
  margin-left: 4px !important;
}

.mb_4 {
  margin-bottom: 4px !important;
}

.pt_8 {
  padding-top: 8px !important;
}

.pr_8 {
  padding-right: 8px !important;
}

.pl_8 {
  padding-left: 8px !important;
}

.pb_8 {
  padding-bottom: 8px !important;
}

.mt_8 {
  margin-top: 8px !important;
}

.mr_8 {
  margin-right: 8px !important;
}

.ml_8 {
  margin-left: 8px !important;
}

.mb_8 {
  margin-bottom: 8px !important;
}

.pt_12 {
  padding-top: 12px !important;
}

.pr_12 {
  padding-right: 12px !important;
}

.pl_12 {
  padding-left: 12px !important;
}

.pb_12 {
  padding-bottom: 12px !important;
}

.mt_12 {
  margin-top: 12px !important;
}

.mr_12 {
  margin-right: 12px !important;
}

.ml_12 {
  margin-left: 12px !important;
}

.mb_12 {
  margin-bottom: 12px !important;
}

.pt_16 {
  padding-top: 16px !important;
}

.pr_16 {
  padding-right: 16px !important;
}

.pl_16 {
  padding-left: 16px !important;
}

.pb_16 {
  padding-bottom: 16px !important;
}

.mt_16 {
  margin-top: 16px !important;
}

.mr_16 {
  margin-right: 16px !important;
}

.ml_16 {
  margin-left: 16px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.pt_20 {
  padding-top: 20px !important;
}

.pr_20 {
  padding-right: 20px !important;
}

.pl_20 {
  padding-left: 20px !important;
}

.pb_20 {
  padding-bottom: 20px !important;
}

.mt_20 {
  margin-top: 20px !important;
}

.mr_20 {
  margin-right: 20px !important;
}

.ml_20 {
  margin-left: 20px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.pt_24 {
  padding-top: 24px !important;
}

.pr_24 {
  padding-right: 24px !important;
}

.pl_24 {
  padding-left: 24px !important;
}

.pb_24 {
  padding-bottom: 24px !important;
}

.mt_24 {
  margin-top: 24px !important;
}

.mr_24 {
  margin-right: 24px !important;
}

.ml_24 {
  margin-left: 24px !important;
}

.mb_24 {
  margin-bottom: 24px !important;
}

.pt_28 {
  padding-top: 28px !important;
}

.pr_28 {
  padding-right: 28px !important;
}

.pl_28 {
  padding-left: 28px !important;
}

.pb_28 {
  padding-bottom: 28px !important;
}

.mt_28 {
  margin-top: 28px !important;
}

.mr_28 {
  margin-right: 28px !important;
}

.ml_28 {
  margin-left: 28px !important;
}

.mb_28 {
  margin-bottom: 28px !important;
}

.pt_32 {
  padding-top: 32px !important;
}

.pr_32 {
  padding-right: 32px !important;
}

.pl_32 {
  padding-left: 32px !important;
}

.pb_32 {
  padding-bottom: 32px !important;
}

.mt_32 {
  margin-top: 32px !important;
}

.mr_32 {
  margin-right: 32px !important;
}

.ml_32 {
  margin-left: 32px !important;
}

.mb_32 {
  margin-bottom: 32px !important;
}

.pt_36 {
  padding-top: 36px !important;
}

.pr_36 {
  padding-right: 36px !important;
}

.pl_36 {
  padding-left: 36px !important;
}

.pb_36 {
  padding-bottom: 36px !important;
}

.mt_36 {
  margin-top: 36px !important;
}

.mr_36 {
  margin-right: 36px !important;
}

.ml_36 {
  margin-left: 36px !important;
}

.mb_36 {
  margin-bottom: 36px !important;
}

.pt_40 {
  padding-top: 40px !important;
}

.pr_40 {
  padding-right: 40px !important;
}

.pl_40 {
  padding-left: 40px !important;
}

.pb_40 {
  padding-bottom: 40px !important;
}

.mt_40 {
  margin-top: 40px !important;
}

.mr_40 {
  margin-right: 40px !important;
}

.ml_40 {
  margin-left: 40px !important;
}

.mb_40 {
  margin-bottom: 40px !important;
}

.pt_44 {
  padding-top: 44px !important;
}

.pr_44 {
  padding-right: 44px !important;
}

.pl_44 {
  padding-left: 44px !important;
}

.pb_44 {
  padding-bottom: 44px !important;
}

.mt_44 {
  margin-top: 44px !important;
}

.mr_44 {
  margin-right: 44px !important;
}

.ml_44 {
  margin-left: 44px !important;
}

.mb_44 {
  margin-bottom: 44px !important;
}

.pt_48 {
  padding-top: 48px !important;
}

.pr_48 {
  padding-right: 48px !important;
}

.pl_48 {
  padding-left: 48px !important;
}

.pb_48 {
  padding-bottom: 48px !important;
}

.mt_48 {
  margin-top: 48px !important;
}

.mr_48 {
  margin-right: 48px !important;
}

.ml_48 {
  margin-left: 48px !important;
}

.mb_48 {
  margin-bottom: 48px !important;
}

.pt_52 {
  padding-top: 52px !important;
}

.pr_52 {
  padding-right: 52px !important;
}

.pl_52 {
  padding-left: 52px !important;
}

.pb_52 {
  padding-bottom: 52px !important;
}

.mt_52 {
  margin-top: 52px !important;
}

.mr_52 {
  margin-right: 52px !important;
}

.ml_52 {
  margin-left: 52px !important;
}

.mb_52 {
  margin-bottom: 52px !important;
}

.pt_56 {
  padding-top: 56px !important;
}

.pr_56 {
  padding-right: 56px !important;
}

.pl_56 {
  padding-left: 56px !important;
}

.pb_56 {
  padding-bottom: 56px !important;
}

.mt_56 {
  margin-top: 56px !important;
}

.mr_56 {
  margin-right: 56px !important;
}

.ml_56 {
  margin-left: 56px !important;
}

.mb_56 {
  margin-bottom: 56px !important;
}

.pt_60 {
  padding-top: 60px !important;
}

.pr_60 {
  padding-right: 60px !important;
}

.pl_60 {
  padding-left: 60px !important;
}

.pb_60 {
  padding-bottom: 60px !important;
}

.mt_60 {
  margin-top: 60px !important;
}

.mr_60 {
  margin-right: 60px !important;
}

.ml_60 {
  margin-left: 60px !important;
}

.mb_60 {
  margin-bottom: 60px !important;
}

.pt_64 {
  padding-top: 64px !important;
}

.pr_64 {
  padding-right: 64px !important;
}

.pl_64 {
  padding-left: 64px !important;
}

.pb_64 {
  padding-bottom: 64px !important;
}

.mt_64 {
  margin-top: 64px !important;
}

.mr_64 {
  margin-right: 64px !important;
}

.ml_64 {
  margin-left: 64px !important;
}

.mb_64 {
  margin-bottom: 64px !important;
}

.pt_68 {
  padding-top: 68px !important;
}

.pr_68 {
  padding-right: 68px !important;
}

.pl_68 {
  padding-left: 68px !important;
}

.pb_68 {
  padding-bottom: 68px !important;
}

.mt_68 {
  margin-top: 68px !important;
}

.mr_68 {
  margin-right: 68px !important;
}

.ml_68 {
  margin-left: 68px !important;
}

.mb_68 {
  margin-bottom: 68px !important;
}

.pt_72 {
  padding-top: 72px !important;
}

.pr_72 {
  padding-right: 72px !important;
}

.pl_72 {
  padding-left: 72px !important;
}

.pb_72 {
  padding-bottom: 72px !important;
}

.mt_72 {
  margin-top: 72px !important;
}

.mr_72 {
  margin-right: 72px !important;
}

.ml_72 {
  margin-left: 72px !important;
}

.mb_72 {
  margin-bottom: 72px !important;
}

.pt_76 {
  padding-top: 76px !important;
}

.pr_76 {
  padding-right: 76px !important;
}

.pl_76 {
  padding-left: 76px !important;
}

.pb_76 {
  padding-bottom: 76px !important;
}

.mt_76 {
  margin-top: 76px !important;
}

.mr_76 {
  margin-right: 76px !important;
}

.ml_76 {
  margin-left: 76px !important;
}

.mb_76 {
  margin-bottom: 76px !important;
}

.pt_80 {
  padding-top: 80px !important;
}

.pr_80 {
  padding-right: 80px !important;
}

.pl_80 {
  padding-left: 80px !important;
}

.pb_80 {
  padding-bottom: 80px !important;
}

.mt_80 {
  margin-top: 80px !important;
}

.mr_80 {
  margin-right: 80px !important;
}

.ml_80 {
  margin-left: 80px !important;
}

.mb_80 {
  margin-bottom: 80px !important;
}

.pt_84 {
  padding-top: 84px !important;
}

.pr_84 {
  padding-right: 84px !important;
}

.pl_84 {
  padding-left: 84px !important;
}

.pb_84 {
  padding-bottom: 84px !important;
}

.mt_84 {
  margin-top: 84px !important;
}

.mr_84 {
  margin-right: 84px !important;
}

.ml_84 {
  margin-left: 84px !important;
}

.mb_84 {
  margin-bottom: 84px !important;
}

.pt_88 {
  padding-top: 88px !important;
}

.pr_88 {
  padding-right: 88px !important;
}

.pl_88 {
  padding-left: 88px !important;
}

.pb_88 {
  padding-bottom: 88px !important;
}

.mt_88 {
  margin-top: 88px !important;
}

.mr_88 {
  margin-right: 88px !important;
}

.ml_88 {
  margin-left: 88px !important;
}

.mb_88 {
  margin-bottom: 88px !important;
}

.pt_92 {
  padding-top: 92px !important;
}

.pr_92 {
  padding-right: 92px !important;
}

.pl_92 {
  padding-left: 92px !important;
}

.pb_92 {
  padding-bottom: 92px !important;
}

.mt_92 {
  margin-top: 92px !important;
}

.mr_92 {
  margin-right: 92px !important;
}

.ml_92 {
  margin-left: 92px !important;
}

.mb_92 {
  margin-bottom: 92px !important;
}

.pt_96 {
  padding-top: 96px !important;
}

.pr_96 {
  padding-right: 96px !important;
}

.pl_96 {
  padding-left: 96px !important;
}

.pb_96 {
  padding-bottom: 96px !important;
}

.mt_96 {
  margin-top: 96px !important;
}

.mr_96 {
  margin-right: 96px !important;
}

.ml_96 {
  margin-left: 96px !important;
}

.mb_96 {
  margin-bottom: 96px !important;
}

.pt_100 {
  padding-top: 100px !important;
}

.pr_100 {
  padding-right: 100px !important;
}

.pl_100 {
  padding-left: 100px !important;
}

.pb_100 {
  padding-bottom: 100px !important;
}

.mt_100 {
  margin-top: 100px !important;
}

.mr_100 {
  margin-right: 100px !important;
}

.ml_100 {
  margin-left: 100px !important;
}

.mb_100 {
  margin-bottom: 100px !important;
}

.pt_104 {
  padding-top: 104px !important;
}

.pr_104 {
  padding-right: 104px !important;
}

.pl_104 {
  padding-left: 104px !important;
}

.pb_104 {
  padding-bottom: 104px !important;
}

.mt_104 {
  margin-top: 104px !important;
}

.mr_104 {
  margin-right: 104px !important;
}

.ml_104 {
  margin-left: 104px !important;
}

.mb_104 {
  margin-bottom: 104px !important;
}

.pt_108 {
  padding-top: 108px !important;
}

.pr_108 {
  padding-right: 108px !important;
}

.pl_108 {
  padding-left: 108px !important;
}

.pb_108 {
  padding-bottom: 108px !important;
}

.mt_108 {
  margin-top: 108px !important;
}

.mr_108 {
  margin-right: 108px !important;
}

.ml_108 {
  margin-left: 108px !important;
}

.mb_108 {
  margin-bottom: 108px !important;
}

.pt_112 {
  padding-top: 112px !important;
}

.pr_112 {
  padding-right: 112px !important;
}

.pl_112 {
  padding-left: 112px !important;
}

.pb_112 {
  padding-bottom: 112px !important;
}

.mt_112 {
  margin-top: 112px !important;
}

.mr_112 {
  margin-right: 112px !important;
}

.ml_112 {
  margin-left: 112px !important;
}

.mb_112 {
  margin-bottom: 112px !important;
}

.pt_116 {
  padding-top: 116px !important;
}

.pr_116 {
  padding-right: 116px !important;
}

.pl_116 {
  padding-left: 116px !important;
}

.pb_116 {
  padding-bottom: 116px !important;
}

.mt_116 {
  margin-top: 116px !important;
}

.mr_116 {
  margin-right: 116px !important;
}

.ml_116 {
  margin-left: 116px !important;
}

.mb_116 {
  margin-bottom: 116px !important;
}

.pt_120 {
  padding-top: 120px !important;
}

.pr_120 {
  padding-right: 120px !important;
}

.pl_120 {
  padding-left: 120px !important;
}

.pb_120 {
  padding-bottom: 120px !important;
}

.mt_120 {
  margin-top: 120px !important;
}

.mr_120 {
  margin-right: 120px !important;
}

.ml_120 {
  margin-left: 120px !important;
}

.mb_120 {
  margin-bottom: 120px !important;
}

.pt_124 {
  padding-top: 124px !important;
}

.pr_124 {
  padding-right: 124px !important;
}

.pl_124 {
  padding-left: 124px !important;
}

.pb_124 {
  padding-bottom: 124px !important;
}

.mt_124 {
  margin-top: 124px !important;
}

.mr_124 {
  margin-right: 124px !important;
}

.ml_124 {
  margin-left: 124px !important;
}

.mb_124 {
  margin-bottom: 124px !important;
}

.pt_128 {
  padding-top: 128px !important;
}

.pr_128 {
  padding-right: 128px !important;
}

.pl_128 {
  padding-left: 128px !important;
}

.pb_128 {
  padding-bottom: 128px !important;
}

.mt_128 {
  margin-top: 128px !important;
}

.mr_128 {
  margin-right: 128px !important;
}

.ml_128 {
  margin-left: 128px !important;
}

.mb_128 {
  margin-bottom: 128px !important;
}

.pt_132 {
  padding-top: 132px !important;
}

.pr_132 {
  padding-right: 132px !important;
}

.pl_132 {
  padding-left: 132px !important;
}

.pb_132 {
  padding-bottom: 132px !important;
}

.mt_132 {
  margin-top: 132px !important;
}

.mr_132 {
  margin-right: 132px !important;
}

.ml_132 {
  margin-left: 132px !important;
}

.mb_132 {
  margin-bottom: 132px !important;
}

.pt_136 {
  padding-top: 136px !important;
}

.pr_136 {
  padding-right: 136px !important;
}

.pl_136 {
  padding-left: 136px !important;
}

.pb_136 {
  padding-bottom: 136px !important;
}

.mt_136 {
  margin-top: 136px !important;
}

.mr_136 {
  margin-right: 136px !important;
}

.ml_136 {
  margin-left: 136px !important;
}

.mb_136 {
  margin-bottom: 136px !important;
}

.pt_140 {
  padding-top: 140px !important;
}

.pr_140 {
  padding-right: 140px !important;
}

.pl_140 {
  padding-left: 140px !important;
}

.pb_140 {
  padding-bottom: 140px !important;
}

.mt_140 {
  margin-top: 140px !important;
}

.mr_140 {
  margin-right: 140px !important;
}

.ml_140 {
  margin-left: 140px !important;
}

.mb_140 {
  margin-bottom: 140px !important;
}

.pt_144 {
  padding-top: 144px !important;
}

.pr_144 {
  padding-right: 144px !important;
}

.pl_144 {
  padding-left: 144px !important;
}

.pb_144 {
  padding-bottom: 144px !important;
}

.mt_144 {
  margin-top: 144px !important;
}

.mr_144 {
  margin-right: 144px !important;
}

.ml_144 {
  margin-left: 144px !important;
}

.mb_144 {
  margin-bottom: 144px !important;
}

.pt_148 {
  padding-top: 148px !important;
}

.pr_148 {
  padding-right: 148px !important;
}

.pl_148 {
  padding-left: 148px !important;
}

.pb_148 {
  padding-bottom: 148px !important;
}

.mt_148 {
  margin-top: 148px !important;
}

.mr_148 {
  margin-right: 148px !important;
}

.ml_148 {
  margin-left: 148px !important;
}

.mb_148 {
  margin-bottom: 148px !important;
}

.pt_152 {
  padding-top: 152px !important;
}

.pr_152 {
  padding-right: 152px !important;
}

.pl_152 {
  padding-left: 152px !important;
}

.pb_152 {
  padding-bottom: 152px !important;
}

.mt_152 {
  margin-top: 152px !important;
}

.mr_152 {
  margin-right: 152px !important;
}

.ml_152 {
  margin-left: 152px !important;
}

.mb_152 {
  margin-bottom: 152px !important;
}

.pt_156 {
  padding-top: 156px !important;
}

.pr_156 {
  padding-right: 156px !important;
}

.pl_156 {
  padding-left: 156px !important;
}

.pb_156 {
  padding-bottom: 156px !important;
}

.mt_156 {
  margin-top: 156px !important;
}

.mr_156 {
  margin-right: 156px !important;
}

.ml_156 {
  margin-left: 156px !important;
}

.mb_156 {
  margin-bottom: 156px !important;
}

.pt_160 {
  padding-top: 160px !important;
}

.pr_160 {
  padding-right: 160px !important;
}

.pl_160 {
  padding-left: 160px !important;
}

.pb_160 {
  padding-bottom: 160px !important;
}

.mt_160 {
  margin-top: 160px !important;
}

.mr_160 {
  margin-right: 160px !important;
}

.ml_160 {
  margin-left: 160px !important;
}

.mb_160 {
  margin-bottom: 160px !important;
}

.pt_164 {
  padding-top: 164px !important;
}

.pr_164 {
  padding-right: 164px !important;
}

.pl_164 {
  padding-left: 164px !important;
}

.pb_164 {
  padding-bottom: 164px !important;
}

.mt_164 {
  margin-top: 164px !important;
}

.mr_164 {
  margin-right: 164px !important;
}

.ml_164 {
  margin-left: 164px !important;
}

.mb_164 {
  margin-bottom: 164px !important;
}

.pt_168 {
  padding-top: 168px !important;
}

.pr_168 {
  padding-right: 168px !important;
}

.pl_168 {
  padding-left: 168px !important;
}

.pb_168 {
  padding-bottom: 168px !important;
}

.mt_168 {
  margin-top: 168px !important;
}

.mr_168 {
  margin-right: 168px !important;
}

.ml_168 {
  margin-left: 168px !important;
}

.mb_168 {
  margin-bottom: 168px !important;
}

.pt_172 {
  padding-top: 172px !important;
}

.pr_172 {
  padding-right: 172px !important;
}

.pl_172 {
  padding-left: 172px !important;
}

.pb_172 {
  padding-bottom: 172px !important;
}

.mt_172 {
  margin-top: 172px !important;
}

.mr_172 {
  margin-right: 172px !important;
}

.ml_172 {
  margin-left: 172px !important;
}

.mb_172 {
  margin-bottom: 172px !important;
}

.pt_176 {
  padding-top: 176px !important;
}

.pr_176 {
  padding-right: 176px !important;
}

.pl_176 {
  padding-left: 176px !important;
}

.pb_176 {
  padding-bottom: 176px !important;
}

.mt_176 {
  margin-top: 176px !important;
}

.mr_176 {
  margin-right: 176px !important;
}

.ml_176 {
  margin-left: 176px !important;
}

.mb_176 {
  margin-bottom: 176px !important;
}

.pt_180 {
  padding-top: 180px !important;
}

.pr_180 {
  padding-right: 180px !important;
}

.pl_180 {
  padding-left: 180px !important;
}

.pb_180 {
  padding-bottom: 180px !important;
}

.mt_180 {
  margin-top: 180px !important;
}

.mr_180 {
  margin-right: 180px !important;
}

.ml_180 {
  margin-left: 180px !important;
}

.mb_180 {
  margin-bottom: 180px !important;
}

.pt_184 {
  padding-top: 184px !important;
}

.pr_184 {
  padding-right: 184px !important;
}

.pl_184 {
  padding-left: 184px !important;
}

.pb_184 {
  padding-bottom: 184px !important;
}

.mt_184 {
  margin-top: 184px !important;
}

.mr_184 {
  margin-right: 184px !important;
}

.ml_184 {
  margin-left: 184px !important;
}

.mb_184 {
  margin-bottom: 184px !important;
}

.pt_188 {
  padding-top: 188px !important;
}

.pr_188 {
  padding-right: 188px !important;
}

.pl_188 {
  padding-left: 188px !important;
}

.pb_188 {
  padding-bottom: 188px !important;
}

.mt_188 {
  margin-top: 188px !important;
}

.mr_188 {
  margin-right: 188px !important;
}

.ml_188 {
  margin-left: 188px !important;
}

.mb_188 {
  margin-bottom: 188px !important;
}

.pt_192 {
  padding-top: 192px !important;
}

.pr_192 {
  padding-right: 192px !important;
}

.pl_192 {
  padding-left: 192px !important;
}

.pb_192 {
  padding-bottom: 192px !important;
}

.mt_192 {
  margin-top: 192px !important;
}

.mr_192 {
  margin-right: 192px !important;
}

.ml_192 {
  margin-left: 192px !important;
}

.mb_192 {
  margin-bottom: 192px !important;
}

.pt_196 {
  padding-top: 196px !important;
}

.pr_196 {
  padding-right: 196px !important;
}

.pl_196 {
  padding-left: 196px !important;
}

.pb_196 {
  padding-bottom: 196px !important;
}

.mt_196 {
  margin-top: 196px !important;
}

.mr_196 {
  margin-right: 196px !important;
}

.ml_196 {
  margin-left: 196px !important;
}

.mb_196 {
  margin-bottom: 196px !important;
}

.pt_200 {
  padding-top: 200px !important;
}

.pr_200 {
  padding-right: 200px !important;
}

.pl_200 {
  padding-left: 200px !important;
}

.pb_200 {
  padding-bottom: 200px !important;
}

.mt_200 {
  margin-top: 200px !important;
}

.mr_200 {
  margin-right: 200px !important;
}

.ml_200 {
  margin-left: 200px !important;
}

.mb_200 {
  margin-bottom: 200px !important;
}

.center {
  text-align: center;
}

.hide_text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

span.hide_text {
  display: inline-block;
  vertical-align: middle;
}

header h1 {
  font-family: "esamanru";
  font-size: 40px;
  font-weight: 700;
  color: #19c791;
}
@media (max-width: 1080px) {
  header h1 {
    font-size: 24px;
    font-weight: 700;
    color: #19c791;
  }
}

strong {
  font-weight: 700 !important;
}

table tr.error {
  background-color: rgba(255, 34, 34, 0.2);
}
table tr.point {
  background-color: #d0eed9;
}
table tr.read_all td {
  color: #19c791 !important;
}
table tr td.error {
  color: #ff2222 !important;
}
table tr td.pd_none {
  padding: 0 !important;
}
table.fixed {
  table-layout: fixed;
}
table.detail {
  border-collapse: collapse;
}
table.detail * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  table.detail * {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
table.detail tr,
table.detail th,
table.detail td {
  white-space: normal;
  padding: 10px;
  border: 1.5px solid #b4b4b4;
}
table.detail tr.hide_text,
table.detail th.hide_text,
table.detail td.hide_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1080px) {
  table.detail tr,
  table.detail th,
  table.detail td {
    padding: 8px;
  }
}
table.detail tr .cell_content_wrap,
table.detail th .cell_content_wrap,
table.detail td .cell_content_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  white-space: normal;
}
table.detail tr .cell_content_wrap.center,
table.detail th .cell_content_wrap.center,
table.detail td .cell_content_wrap.center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
table.detail tr .cell_content_wrap input:not([type="radio"]),
table.detail th .cell_content_wrap input:not([type="radio"]),
table.detail td .cell_content_wrap input:not([type="radio"]) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  white-space: normal;
  word-break: break-all;
}
table.detail tr .cell_content_wrap textarea,
table.detail th .cell_content_wrap textarea,
table.detail td .cell_content_wrap textarea {
  padding: 10px;
  width: 100%;
  height: 102px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  resize: none;
}
table.detail tr ul,
table.detail th ul,
table.detail td ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
@media (max-width: 1080px) {
  table.detail tr ul.attach_files,
  table.detail th ul.attach_files,
  table.detail td ul.attach_files {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}
table.detail tr ul.attach_files img,
table.detail th ul.attach_files img,
table.detail td ul.attach_files img {
  width: 200px;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 1080px) {
  table.detail tr ul.attach_files img,
  table.detail th ul.attach_files img,
  table.detail td ul.attach_files img {
    width: 100%;
    height: auto;
  }
}
table.detail tr ul.attach_files img:hover,
table.detail th ul.attach_files img:hover,
table.detail td ul.attach_files img:hover {
  opacity: 0.6;
  cursor: pointer;
}
table.detail tr ul.attach_files img:hover::before,
table.detail th ul.attach_files img:hover::before,
table.detail td ul.attach_files img:hover::before {
  content: "크게보기";
}
table.detail th {
  width: 158px;
  font-weight: 700;
  background-color: #f0f0f0;
}
@media (max-width: 1080px) {
  table.detail th {
    width: unset;
  }
}
table.detail td table.inner_table {
  border: none !important;
}
table.detail td table.inner_table tr,
table.detail td table.inner_table th,
table.detail td table.inner_table td {
  background-color: #fff !important;
  border: none !important;
}
table.detail.bg_none tr,
table.detail.bg_none th,
table.detail.bg_none td {
  background-color: transparent !important;
}
table.detail.outline_none {
  border: none !important;
}
table.detail.border_none tr,
table.detail.border_none th,
table.detail.border_none td {
  border: none !important;
}
table.detail.border_none th {
  border-bottom: 1.5px solid #b4b4b4;
}
table.detail.align_l td {
  text-align: left !important;
}
table.detail.align_c td {
  text-align: center !important;
}
table.detail.border_row tr,
table.detail.border_row th,
table.detail.border_row td {
  border: none !important;
}
table.detail.border_row tr:not(:last-of-type) {
  border-bottom: 1.5px solid #b4b4b4 !important;
}

label {
  position: relative;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.color_main {
  color: #19c791;
}

.container {
  margin: 0 auto;
  padding: 128px calc((100vw - 1366px) / 2) 0;
  min-height: 100vh;
  overflow-y: auto;
}
@media (max-width: 1080px) {
  .container {
    min-height: var(--vh);
    padding: 40px 0 32px;
  }
  .container.nav_none {
    padding: 72px 0 32px;
  }
}
@media (min-width: 1081px) {
  .container.pd_none {
    padding: 0;
  }
}
.container.align_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  .container.align_center {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.container.header_none {
  padding: 0 calc((100vw - 1200px) / 2) 0;
}
.container.background_dim {
  background-color: rgba(0, 0, 0, 0.6);
}
.container.background_white {
  background-color: #fff;
}
.container .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: relative;
  left: 0px;
  margin: 0 auto;
  padding: 0 4%;
  padding-top: 40px;
  width: 100%;
  max-width: 1500px;
  max-height: 100%;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  /* 게시판 구조에서 사용하는 상위 엘리먼트 */
}
@media (max-width: 1080px) {
  .container .inner {
    padding: 32px 24px 0;
  }
}
.container .inner header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.container .inner > header {
  padding-bottom: 20px;
  border-bottom: 2px solid #555;
}
@media (max-width: 1080px) {
  .container .inner > header {
    padding-bottom: 12px;
  }
}
.container .inner > header h2 {
  font-size: 24px;
  font-weight: 800;
  color: #191919;
}
@media (max-width: 1080px) {
  .container .inner > header h2 {
    font-size: 18px;
  }
}
.container .inner > header .btn_group {
  width: 396px;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1080px) {
  .container .inner > header .btn_group {
    width: unset;
  }
}
.container .inner > header .btn_default {
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 1080px) {
  .container .inner .page_handling_wrap {
    width: 100%;
  }
}
.container .inner .page_handling_wrap ~ .board_wrap {
  margin-top: 48px;
}
@media (max-width: 1080px) {
  .container .inner .page_handling_wrap ~ .board_wrap {
    margin-top: 32px;
  }
}
.container .inner .page_handling_wrap .search_wrap {
  margin-bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
.container .inner .page_handling_wrap .search_wrap input {
  width: 443px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .container .inner .page_handling_wrap .search_wrap input {
    width: calc(46% - 4px);
    font-size: 14px;
  }
}
@media (max-width: 1080px) {
  .container .inner .page_handling_wrap .search_wrap {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    margin-bottom: 32px;
  }
  .container .inner .page_handling_wrap .search_wrap .selectbox_wrap {
    width: calc(30% - 4px);
    font-size: 12px;
  }
}
@media (max-width: 1080px) {
  .container .inner .page_handling_wrap .search_wrap .btn_group {
    width: auto;
  }
}
.container .inner .page_handling_wrap .search_wrap .btn_group .search {
  width: 78px;
}
.container .inner .board_wrap {
  padding-bottom: 36px;
  width: 100%;
}
.container .inner .board_wrap header {
  padding-bottom: 20px;
  border-bottom: 2px solid #191919;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap header {
    padding-bottom: 12px;
  }
}
.container .inner .board_wrap header h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 34px;
  font-size: 24px;
  font-weight: 800;
  color: #191919;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap header h2 {
    font-size: 18px;
  }
}
.container .inner .board_wrap header .btn_group {
  width: unset;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
@media (min-width: 1081px) {
  .container .inner .board_wrap header .btn_group {
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px;
  }
}
.container .inner .board_wrap header .btn_group button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 160px;
  height: 32px;
  line-height: 32px;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap header .btn_group button.guide {
    width: unset;
  }
}
@media (max-width: 1080px) {
  .container .inner .board_wrap header .btn_group button.add,
  .container .inner .board_wrap header .btn_group button.excel {
    padding: 6px 12px;
    width: unset;
    font-size: 0;
  }
  .container .inner .board_wrap header .btn_group button.add::before,
  .container .inner .board_wrap header .btn_group button.excel::before {
    content: "등록";
    font-size: 14px;
  }
}
.container .inner .board_wrap header .btn_group button.excel {
  background-color: #fff;
  color: #191919;
  border: 1.5px solid #19c791;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap header .btn_group button.excel::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("./assets/img/icon/ic_excel.png") no-repeat;
    background-size: 20px;
    content: "";
  }
}
.container .inner .board_wrap table {
  table-layout: fixed;
  margin-top: 44px;
  text-align: center;
  border-collapse: collapse;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap table {
    margin-top: 16px;
  }
}
.container .inner .board_wrap table * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap table * {
    font-size: 12px;
  }
}
.container .inner .board_wrap table thead tr th {
  padding: 12px 4px;
  font-weight: 700;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap table thead th {
    border: 1px solid #fff;
  }
  .container .inner .board_wrap table thead th .selectbox_wrap {
    width: 100%;
    height: 24px;
    border-radius: 6px;
  }
}
.container .inner .board_wrap table tbody tr td {
  white-space: normal;
  padding: 24px 4px;
}
.container .inner .board_wrap table tbody tr td.hide_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1080px) {
  .container .inner .board_wrap table tbody tr td {
    padding: 12px 4px;
  }
}
.container .inner .board_wrap table tr {
  cursor: pointer;
}
.container .inner .board_wrap table th {
  background-color: #f0f0f0;
}
.container .inner .board_wrap table td {
  position: relative;
}
.container .inner .board_wrap table td:hover .detail_box {
  display: block;
}
.container .inner .board_wrap table td .detail_box {
  display: none;
  position: absolute;
  left: 70%;
  bottom: 40%;
  padding: 8px;
  background-color: #fff;
  border: 1.5px solid #19c791;
  z-index: 1;
}
.container .inner .board_wrap table td .detail_box dd {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  line-height: 24px;
}
.container .inner .board_wrap table td .student_class_info {
  display: inline-block;
  height: 33px;
  line-height: 33px;
}
.container .inner .page_content_wrap {
  margin-top: 44px;
  width: 100%;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap {
    margin-top: 24px;
  }
}
.container .inner .page_content_wrap table,
.container .inner .page_content_wrap tr,
.container .inner .page_content_wrap th,
.container .inner .page_content_wrap td {
  border: 1.5px solid #b4b4b4;
}
.container .inner .page_content_wrap table ul,
.container .inner .page_content_wrap tr ul,
.container .inner .page_content_wrap th ul,
.container .inner .page_content_wrap td ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
.container .inner .page_content_wrap table {
  border-collapse: collapse;
}
.container .inner .page_content_wrap table th {
  width: 160px;
  background-color: #f0f0f0;
}
.container .inner .page_content_wrap table td.target_list ul {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 12px;
}
.container .inner .page_content_wrap table td.target_list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 112px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #b7b7b7;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap table td.target_list ul li {
    font-size: 12px;
  }
}
.container .inner .page_content_wrap table td.target_list ul li.read {
  background-color: #72d29a;
}
.container .inner .page_content_wrap .phone_frame_wrap {
  position: relative;
  margin: 56px auto 0;
  padding: 52px;
  width: 436px;
  height: 867px;
  background: url(./assets/img/frame_phone.png) no-repeat center center;
  overflow: hidden;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .phone_frame_wrap {
    margin-top: 32px;
    padding: 10vw;
    width: 100%;
    height: 178vw;
    background-position: top center;
    background-size: contain;
  }
}
.container .inner .page_content_wrap .phone_frame_wrap img {
  display: block;
}
.container .inner .page_content_wrap .phone_frame_wrap .chat_title_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .chat_title_wrap
  .chat_title_detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #191919;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .phone_frame_wrap
    .chat_title_wrap
    .chat_title_detail {
    font-size: 14px;
  }
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .chat_title_wrap
  .chat_title_detail
  span {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .chat_title_wrap
  .chat_title_detail
  section:first-child {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #ccc;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .phone_frame_wrap
    .chat_title_wrap
    .chat_title_detail
    section:first-child {
    width: 32px;
    height: 32px;
  }
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .chat_title_wrap
  .chat_title_detail
  section:first-child
  img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.container .inner .page_content_wrap .phone_frame_wrap .meassage_wrap {
  overflow-y: auto;
  height: 80%;
}
.container .inner .page_content_wrap .phone_frame_wrap .meassage_wrap ul {
  padding-left: 60px;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .phone_frame_wrap .meassage_wrap ul {
    padding-left: 38px;
  }
}
.container .inner .page_content_wrap .phone_frame_wrap .meassage_wrap ul li {
  max-width: 200px;
  border-radius: 10px;
  overflow: hidden;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  ul
  li:not(:last-of-type) {
  margin-bottom: 12px;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  ul
  li
  img {
  width: 100%;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  .message_detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  .message_detail
  span {
  font-size: 12px;
  font-weight: 200;
  color: #939393;
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  .message_detail
  p {
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 700;
  color: #191919;
  border: 10px;
  background-color: #f7f7f7;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .phone_frame_wrap
    .meassage_wrap
    .message_detail
    p {
    font-size: 12px;
  }
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  .message_detail
  section:first-child {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #ccc;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .phone_frame_wrap
    .meassage_wrap
    .message_detail
    section:first-child {
    width: 28px;
    height: 28px;
  }
}
.container
  .inner
  .page_content_wrap
  .phone_frame_wrap
  .meassage_wrap
  .message_detail
  section:first-child
  img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.container .inner .page_content_wrap .phone_frame_wrap .bottom_bar {
  position: absolute;
  bottom: 16px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .phone_frame_wrap .bottom_bar {
    width: 80vw;
    bottom: 10vw;
  }
}
.container .inner .page_content_wrap .target_list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 12px;
  width: 100%;
  border: none;
}
.container .inner .page_content_wrap .target_list.add li {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  background-color: #ffcd4b;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .target_list.add li {
    font-size: 12px;
  }
}
.container .inner .page_content_wrap .target_list li {
  width: auto !important;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 6px 24px;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .target_list li {
    padding: 0;
    width: calc(33.3333333333% - 8px) !important;
    height: 24px;
    font-size: 12px;
    border-radius: 6px;
  }
}
.container .inner .page_content_wrap .input_main_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_main_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.container .inner .page_content_wrap .input_main_wrap * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
.container .inner .page_content_wrap .input_main_wrap .input_select_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 254px;
  height: 388px;
  border: 1.5px solid #b4b4b4;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_main_wrap .input_select_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    min-height: 100px;
  }
  .container .inner .page_content_wrap .input_main_wrap .input_select_wrap * {
    font-size: 12px;
  }
}
@media (max-width: 1080px) and (min-width: 1081px) {
  .container
    .inner
    .page_content_wrap
    .input_main_wrap
    .input_select_wrap:first-of-type {
    padding-bottom: 20px;
  }
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_main_wrap
    .input_select_wrap:not(:last-of-type) {
    border-bottom: none;
  }
}
.container .inner .page_content_wrap .input_main_wrap .input_select_wrap h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  padding: 12px;
  font-weight: 700;
  background-color: #f0f0f0;
  border-bottom: 1.5px solid #b4b4b4;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_main_wrap .input_select_wrap h3 {
    width: 30%;
    border-right: 1.5px solid #b4b4b4;
    border-bottom: none;
  }
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap.content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap.content
  .input_select_item {
  overflow: hidden;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap.content
  textarea {
  padding: 0;
  width: 100%;
  height: 100%;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item {
  padding: 20px;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-y: scroll;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_main_wrap
    .input_select_wrap
    .input_select_item {
    width: unset;
    height: 120px;
    padding: 8px;
  }
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item
  li
  label {
  display: inline-block;
  margin-bottom: 12px;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item
  li
  label
  input {
  margin-right: 4px;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item
  li
  .btn_is_one_ime {
  margin-left: 4px;
  padding: 4px;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  background-color: #b7b7b7;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item
  li
  .btn_is_one_ime.active {
  background-color: #19c791;
}
.container
  .inner
  .page_content_wrap
  .input_main_wrap
  .input_select_wrap
  .input_select_item
  p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 12px;
  padding: 4px 12px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  background-color: #ffcd4b;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_main_wrap
    .input_select_wrap
    .input_select_item
    p {
    font-size: 12px;
  }
}
.container .inner .page_content_wrap .input_sub_wrap {
  margin-top: 52px;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_sub_wrap {
    margin-top: 24px;
  }
}
.container .inner .page_content_wrap .input_sub_wrap ~ .btn_group {
  margin: 64px 0;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_sub_wrap ~ .btn_group {
    margin: 24px 0 0;
  }
}
.container .inner .page_content_wrap .input_sub_wrap .detail {
  table-layout: fixed;
}
@media (max-width: 1080px) {
  .container .inner .page_content_wrap .input_sub_wrap .detail th {
    width: 30%;
  }
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_sub_wrap
    .detail
    .cell_content_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  input:not([type="radio"]) {
  height: 40px;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_sub_wrap
    .detail
    .cell_content_wrap
    input:not([type="radio"]) {
    height: 28px;
  }
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .file_list {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: calc(100% - 112px);
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_sub_wrap
    .detail
    .cell_content_wrap
    .file_list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .file_list
  li {
  max-width: calc(33.3333333333% - 12px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_sub_wrap
    .detail
    .cell_content_wrap
    .file_list
    li {
    margin-top: 12px;
    max-width: unset;
    width: 100%;
  }
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .file_list
  li
  span {
  display: inline-block;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .file_list
  li
  button {
  width: 24px;
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .btn_group {
  width: unset;
}
.container
  .inner
  .page_content_wrap
  .input_sub_wrap
  .detail
  .cell_content_wrap
  .btn_group
  .green3 {
  width: 112px;
}
@media (max-width: 1080px) {
  .container
    .inner
    .page_content_wrap
    .input_sub_wrap
    .detail
    .cell_content_wrap
    .btn_group
    .green3 {
    width: 80px;
  }
}
.container .inner .page_content_wrap .ul_1depth {
  padding-left: 6px;
}
.container .inner .page_content_wrap .ul_2depth {
  padding-left: 12px;
}
.container .inner .page_content_wrap .ul_3depth {
  padding-left: 18px;
}
.container .inner .page_content_wrap .ul_4depth {
  padding-left: 24px;
}
.container .inner .page_content_wrap .ul_5depth {
  padding-left: 30px;
}
.container .inner .page_content_wrap .ul_6depth {
  padding-left: 36px;
}
.container .inner .page_content_wrap .ul_7depth {
  padding-left: 42px;
}
.container .inner .page_content_wrap .ul_8depth {
  padding-left: 48px;
}
.container .inner .page_content_wrap .ul_9depth {
  padding-left: 54px;
}
.container .inner .page_content_wrap .ul_10depth {
  padding-left: 60px;
}

table input {
  border: 1px solid #ebdfdf;
}

table td {
  text-overflow: ellipsis;
  white-space: nowrap;
}
table td * {
  text-overflow: ellipsis;
  white-space: nowrap;
}

button a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

select {
  padding: 14px 10px;
  background: #fff;
  border: 1px solid #b4b4b4;
  border-radius: 10px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex.jc_c {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.flex.jc_sp_bw {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex.jc_sp_ar {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.flex.jc_sp_ev {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.flex.jc_fs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.flex.jc_fe {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.flex.ai_fs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex.ai_fe {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex.ai_st {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.flex.fd_c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

textarea {
  padding: 24px 26px;
  resize: none;
}
textarea::-webkit-input-placeholder {
  color: #505050;
}
textarea::-moz-placeholder {
  color: #505050;
}
textarea:-ms-input-placeholder {
  color: #505050;
}
textarea::-ms-input-placeholder {
  color: #505050;
}
textarea::placeholder {
  color: #505050;
}

.visually_hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.title {
  font-family: "esamanru";
}
.title .tit_point {
  font-family: "esamanru";
  color: #ffed4b;
}

.bf_auth_badge,
.biz_auth_badge,
.not_match_badge {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  display: inline-block;
  margin-right: 16px;
  padding: 0 20px;
  height: 33px;
  line-height: 33px;
  border-radius: 8px;
  letter-spacing: -0.02em;
  vertical-align: middle;
}
@media (max-width: 1080px) {
  .bf_auth_badge,
  .biz_auth_badge,
  .not_match_badge {
    margin-right: 8px;
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 1px;
  }
}

.bf_auth_badge {
  background-color: #ffed4b;
}
@media (max-width: 1080px) {
  .bf_auth_badge {
    background: url("./assets/img/icon/ic_betterflying.png") no-repeat;
    background-size: contain;
  }
}
.bf_auth_badge::after {
  content: "버터플라잉 인증";
}
@media (max-width: 1080px) {
  .bf_auth_badge::after {
    content: "";
  }
}

.biz_auth_badge {
  background-color: #ffcd4b;
}
@media (max-width: 1080px) {
  .biz_auth_badge {
    background: url("./assets/img/icon/ic_owner.png") no-repeat;
    background-size: contain;
  }
}
@media (min-width: 1081px) {
  .biz_auth_badge::after {
    content: "대표님";
  }
}

.not_match_badge {
  margin-left: 0;
  padding-left: 0;
}
@media (max-width: 1080px) {
  .not_match_badge {
    background: url("./assets/img/icon/ic_not_certified.png") no-repeat;
    background-size: contain;
  }
}
.not_match_badge::after {
  font-size: 15px;
  font-weight: 700;
  color: #191919;
  content: "증빙과 일치하지 않습니다.";
}
@media (max-width: 1080px) {
  .not_match_badge::after {
    content: "";
  }
}

.level_list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1080px) {
  .level_list ul {
    -webkit-column-gap: 6px;
    -moz-column-gap: 6px;
    column-gap: 6px;
  }
}
.level_list .level_item {
  padding: 6px 24px;
  color: #191919;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  .level_list .level_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0;
    width: 42px;
    height: 28px;
    font-size: 12px;
    font-weight: 400;
    color: inherit;
    border-radius: 6px;
  }
}

.target_1 {
  background-color: #d0eed9;
}
.target_2 {
  background-color: #a7e7bf;
}
.target_3 {
  background-color: #72d29a;
}
.target_4 {
  background-color: #51c581;
}

.error_text {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 700;
  color: #ff2222;
}
@media (max-width: 1080px) {
  .error_text {
    font-size: 12px;
    font-weight: 700;
    color: #ff2222;
  }
}

.bottom_desc {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}

.guardian {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  width: 20px;
  height: 20px;
  vertical-align: top;
  z-index: 10;
}
.guardian::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("./assets/img/icon/ic_guardian.png") no-repeat;
  background-size: contain;
}
.guardian:hover::after {
  content: "보호자 연락처";
  position: absolute;
  top: 12px;
  display: inline-block;
  padding: 8px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #b4b4b4;
  z-index: 10;
}

@media (max-width: 1080px) {
  .desktop {
    display: none !important;
  }
}
@media (min-width: 1081px) {
  .mobile {
    display: none !important;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  color: #fff;
  background-color: #19c791;
  z-index: 10;
}
#header .login_info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0 32px;
  width: 100%;
  height: 60px;
  background-color: #19c791;
  z-index: 10;
}
@media (max-width: 1080px) {
  #header .login_info {
    padding: 0 12px;
    height: 40px;
  }
}
@media (max-width: 1080px) {
  #header .login_info h1 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 24px;
    z-index: 0;
  }
}
#header .login_info h1 strong,
#header .login_info h1 span {
  font-family: "esamanru";
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}
#header .login_info h1 span {
  font-size: 20px;
  font-weight: 700;
  color: #ffcd4b;
}
#header .login_info h1 img {
  width: 100%;
}
#header .login_info h1 img.back {
  width: 8px;
}
#header .login_info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#header .login_info ul * {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
#header .login_info ul .my_wrap {
  margin-right: 29px;
  line-height: 20px;
}
#header .login_info ul .my_wrap span {
  font-weight: 800;
  text-decoration: underline;
}
#header .login_info ul .logout_wrap button {
  text-decoration: underline;
}
#header .login_info .header_title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: -36px;
  line-height: 40px;
}
#header .side_menu {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  top: 0;
  left: 0;
  padding: 8px;
  width: 200px;
  height: var(--vh);
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
#header .side_menu .dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--vh);
  background-color: transparent;
  content: "";
}
#header .side_menu * {
  font-size: 14px;
  font-weight: 400;
  color: #b4b4b4;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
#header .side_menu li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 8px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
}
#header .side_menu li .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
#header .side_menu li.home_btn {
  border-bottom: 1px solid #b4b4b4;
  border-radius: 0;
}
#header .side_menu li.home_btn span {
  font-size: 14px;
  font-weight: 700;
  color: #191919;
}
#header .side_menu li:not(:last-of-type) {
  margin-bottom: 20px;
}
#header .side_menu li.active {
  background-color: #e0faf2;
}
#header .side_menu li.active span {
  color: #19c791;
}
#header .side_menu li.gap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#header .side_menu li.logout {
  border-top: 1px solid #b4b4b4;
  border-radius: 0;
}
#header .side_menu li .icon_wrap {
  width: 24px;
}
#header .side_menu li .icon_wrap img {
  display: block;
  width: 100%;
}
#header .side_menu li span {
  display: block;
}
@media (max-width: 1080px) {
  #header.show_nav ~ .container {
    padding: 90px 0 0px;
  }
}
#header.show_nav .gnb.mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#header .gnb.mobile {
  display: none;
  height: 50px;
  background-color: #fff;
}
#header .gnb.mobile ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
#header .gnb.mobile ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: #b7b7b7;
  width: 25%;
  height: 100%;
  border-bottom: 2px solid #d9d9d9;
}
#header .gnb.mobile ul li.active {
  color: #19c791;
  border-color: #19c791;
}
#header .gnb.desktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 20px;
  font-weight: 700;
  color: #505050;
  height: 68px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 30px #ccc;
  box-shadow: 0px 0px 30px #ccc;
}
#header .gnb.desktop ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 102px;
  -moz-column-gap: 102px;
  column-gap: 102px;
}
#header .gnb.desktop ul li.active {
  font-size: 20px;
  font-weight: 700;
  color: #19c791;
}

input:not([type="checkbox"]):not([type="radio"]) {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  height: 50px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  color: #191919;
  background-color: #fff;
}
@media (max-width: 1080px) {
  input:not([type="checkbox"]):not([type="radio"]) {
    height: 40px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
input:not([type="checkbox"]):not([type="radio"]).hidden {
  position: fixed;
  left: -9999px;
  width: 0;
  height: 0;
}
input:not([type="checkbox"]):not([type="radio"]).small {
  width: 164px !important;
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
@media (max-width: 1080px) {
  input:not([type="checkbox"]):not([type="radio"]).small {
    width: calc(40vw - 28px) !important;
  }
}
input:not([type="checkbox"]):not([type="radio"]).half {
  width: 254px !important;
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
@media (max-width: 1080px) {
  input:not([type="checkbox"]):not([type="radio"]).half {
    width: calc(50vw - 28px) !important;
  }
}
input:not([type="checkbox"]):not([type="radio"]).medium {
  width: 443px !important;
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
input:not([type="checkbox"]):not([type="radio"]).auto {
  width: unset !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
input:not([type="checkbox"]):not([type="radio"]).full {
  width: 100% !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
input:not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder {
  color: #505050;
}
input:not([type="checkbox"]):not([type="radio"])::-moz-placeholder {
  color: #505050;
}
input:not([type="checkbox"]):not([type="radio"]):-ms-input-placeholder {
  color: #505050;
}
input:not([type="checkbox"]):not([type="radio"])::-ms-input-placeholder {
  color: #505050;
}
input:not([type="checkbox"]):not([type="radio"])::placeholder {
  color: #505050;
}
input:not([type="checkbox"]):not([type="radio"]):-moz-read-only {
  background-color: #e4e4e4;
}
input:not([type="checkbox"]):not([type="radio"]):read-only {
  background-color: #e4e4e4;
}
input:not([type="checkbox"]):not([type="radio"]).error {
  background-color: rgba(255, 34, 34, 0.2);
}

input[type="checkbox"] {
  position: relative;
  width: 17px;
  height: 0;
}
@media (max-width: 1080px) {
  input[type="checkbox"] {
    width: 12px;
  }
}
input[type="checkbox"]::before {
  position: absolute;
  left: 0;
  bottom: -2px;
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url("./assets/img/icon/ic_checkbox.png") no-repeat;
  content: "";
  cursor: pointer;
}
@media (max-width: 1080px) {
  input[type="checkbox"]::before {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
  }
}
input[type="checkbox"]:checked::before {
  background: url("./assets/img/icon/ic_checkbox_checked.png") no-repeat;
}
@media (max-width: 1080px) {
  input[type="checkbox"]:checked::before {
    background-size: 12px 12px;
  }
}

.input_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  row-gap: 24px;
  width: 538px;
}
@media (max-width: 1080px) {
  .input_group {
    row-gap: 28px;
    width: 100%;
  }
}
.input_group .input_item_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  row-gap: 20px;
  width: 100%;
}
@media (max-width: 1080px) {
  .input_group .input_item_group {
    row-gap: 8px;
  }
}
.input_group .input_item_group.type_2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
@media (max-width: 1080px) {
  .input_group .input_item_group.type_2 {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
  }
}
.input_group .input_item_group.type_2 dl {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.input_group .input_item_group.type_2 dl dd {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.input_group .input_item_group dl {
  width: 100%;
}
.input_group .input_item_group dl.agree {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: space-between;
  -ms-flex-align: space-between;
  align-items: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  row-gap: 12px;
}
.input_group .input_item_group dl.agree dd label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
}
.input_group .input_item_group dl.agree dd button {
  font-size: 12px;
  font-weight: 300;
  color: #191919;
}
.input_group .input_item_group dl dt {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  margin-bottom: 12px;
}
@media (max-width: 1080px) {
  .input_group .input_item_group dl dt {
    font-size: 14px;
    font-weight: 700;
    color: inherit;
  }
}
.input_group .input_item_group dl dd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 16px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .input_group .input_item_group dl dd {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
.input_group .input_item_group dl dd.pw {
  position: relative;
}
.input_group .input_item_group dl dd.pw .show_pw {
  position: absolute;
  right: 9px;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .input_group .input_item_group dl dd.pw .show_pw {
    width: 24px;
    height: 24px;
  }
}
.input_group .input_item_group dl dd input[type="file"] {
  display: none;
}
.input_group .input_item_group dl .input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 10px;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  border-radius: 10px;
  border: 1.5px solid #b4b4b4;
}
@media (max-width: 1080px) {
  .input_group .input_item_group dl .input {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
    height: 40px;
  }
}
.input_group .input_item_group dl .input.half {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

input.disabled {
  background-color: #e4e4e4;
}

button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
button.line {
  text-decoration: underline;
}
button:disabled {
  color: #fff !important;
  background-color: #b7b7b7 !important;
}

.btn_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  width: 100%;
}
@media (max-width: 1080px) {
  .btn_group {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
.btn_group.short {
  display: inline-block;
  width: unset;
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.btn_group.center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn_group.type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 80px;
}
@media (max-width: 1080px) {
  .btn_group.type {
    margin-top: 40px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    row-gap: 24px;
    height: 100%;
  }
}
.btn_group.type .btn_default.type {
  font-size: 18px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  .btn_group.type .btn_default.type {
    width: 100% !important;
    height: 48px;
    font-size: 14px;
  }
}
.btn_group .btn_large {
  display: inline-block;
  padding: 40px 52px;
  font-size: 24px;
  font-weight: 700;
  color: forestgreen;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
}
.btn_group .btn_large.wide {
  padding: 40px 100px;
}
.btn_group .btn_default {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  width: 100%;
  height: 50px;
  padding: 6px;
  text-align: center;
  background-color: #19c791;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  .btn_group .btn_default {
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }
}
.btn_group .btn_default.permission:disabled {
  visibility: hidden;
}
.btn_group .btn_default.alert {
  background-color: rgba(255, 34, 34, 0.8);
  color: #fff !important;
}
.btn_group .btn_default.del {
  background-color: #b7b7b7;
}
.btn_group .btn_default.guide {
  background-color: #ececec;
  color: #505050;
  height: 33px;
}
@media (max-width: 1080px) {
  .btn_group .btn_default.guide {
    height: auto;
    background-color: transparent;
  }
}
@media (max-width: 1080px) {
  .btn_group .btn_default.guide a {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.btn_group .btn_default.guide a::before {
  width: 17px;
  height: 21px;
  margin-right: 6px;
  background: url("./assets/img/icon/ic_download.png") no-repeat left
    top/contain;
  content: "";
}
@media (max-width: 1080px) {
  .btn_group .btn_default.guide a::before {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: url("./assets/img/icon/ic_download_mc.png") no-repeat left
      center/contain;
  }
}
.btn_group .btn_default.half {
  width: 254px;
}
@media (max-width: 1080px) {
  .btn_group .btn_default.half {
    width: calc(50vw - 28px) !important;
  }
}
.btn_group .btn_default.border {
  color: #505050;
  background-color: #fff;
  border: 1px solid #19c791;
}
.btn_group .btn_default.slim {
  height: 33px;
  font-size: 18px;
  font-weight: 700;
  color: #fff !important;
}
@media (max-width: 1080px) {
  .btn_group .btn_default.slim {
    height: 24px;
    font-size: 12px !important;
  }
}
.btn_group .btn_default.small {
  width: 112px;
}
.btn_group .btn_default.medium {
  width: 160px;
}
@media (max-width: 1080px) {
  .btn_group .btn_default.medium {
    width: 27.7777vw;
  }
}
.btn_group .btn_default.yellow {
  background-color: #ffcd4b;
}
.btn_group .btn_default.green3 {
  background-color: #72d29a;
}
.btn_group .btn_default label {
  display: inline-block;
  width: 100%;
  empty-cells: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #fff !important;
}
@media (max-width: 1080px) {
  .btn_group .btn_default label {
    font-size: 14px;
    font-weight: 700;
    color: #fff !important;
  }
}

.btn_toggle {
  display: block;
  margin: 0;
  padding: 4px 0;
  font-size: 0 !important;
}
.btn_toggle .btn_toggle_handler_wrap {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 25px;
  border-radius: 10px;
  background-color: #b7b7b7;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .btn_toggle .btn_toggle_handler_wrap {
    width: 32px;
    height: 12px;
  }
}
.btn_toggle .btn_toggle_handler_wrap .btn_toggle_handler {
  position: absolute;
  top: 50%;
  left: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
@media (max-width: 1080px) {
  .btn_toggle .btn_toggle_handler_wrap .btn_toggle_handler {
    left: 9px;
    width: 9px;
    height: 9px;
  }
}
.btn_toggle input[id^="btnToggle"] {
  display: none;
}
.btn_toggle input[id^="btnToggle"]:checked + .btn_toggle_handler_wrap {
  background-color: #19c791;
}
.btn_toggle
  input[id^="btnToggle"]:checked
  + .btn_toggle_handler_wrap
  .btn_toggle_handler {
  left: calc(100% - 18px);
}
@media (max-width: 1080px) {
  .btn_toggle
    input[id^="btnToggle"]:checked
    + .btn_toggle_handler_wrap
    .btn_toggle_handler {
    left: calc(100% - 9px);
  }
}

#pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
  width: 100%;
  margin: 0 auto;
}
#pagination .mobile {
  font-size: 14px;
}
#pagination button {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  width: 32px;
  height: 32px;
  background-color: #d9d9d9;
}
@media (max-width: 1080px) {
  #pagination button {
    width: 24px;
    height: 24px;
    font-size: 16px;
    border: 1px solid #b4b4b4;
    background-color: #fff;
  }
}
#pagination button.active {
  color: #fff;
  background-color: #939393;
}

.modal {
  position: absolute;
}
.modal .modal {
  z-index: 100;
}
.modal.dim::before {
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  z-index: 10;
}
.modal .table_scroll_wrap {
  overflow: auto;
}
.modal .empty_data {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  text-align: center;
  line-height: 30px;
}
@media (max-width: 1080px) {
  .modal .empty_data {
    font-size: 14px;
  }
}
.modal table {
  text-align: left;
}
.modal table * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal table * {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
.modal table th,
.modal table td {
  padding: 10px 0;
}
.modal table th:not(:last-of-type),
.modal table td:not(:last-of-type) {
  padding-right: 28px;
}
@media (max-width: 1080px) {
  .modal table th,
  .modal table td {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
.modal table th .cell_content_wrap,
.modal table td .cell_content_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
.modal table th .cell_content_wrap.worked_date,
.modal table td .cell_content_wrap.worked_date {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
.modal table th .cell_content_wrap.worked_date input:not([type="checkbox"]),
.modal table td .cell_content_wrap.worked_date input:not([type="checkbox"]) {
  width: 188px;
}
.modal table th .cell_content_wrap .btn_group,
.modal table td .cell_content_wrap .btn_group {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.modal table th .cell_content_wrap .search,
.modal table td .cell_content_wrap .search {
  width: 78px;
}
.modal table th .cell_content_wrap input,
.modal table td .cell_content_wrap input {
  border: 1.5px solid #b4b4b4;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal table th {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal table th {
    font-size: 14px;
    font-weight: 700;
    color: inherit;
  }
}
.modal table dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.modal .modal_content_wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  padding: 32px;
  max-height: 100vh;
  overflow: auto;
  border-radius: 20px;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1080px) {
  .modal .modal_content_wrap {
    -webkit-transform: unset;
    transform: unset;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 28px 24px;
    width: 100vw !important;
    max-width: 100vw;
    height: 100%;
    border-radius: 0;
  }
}
@media (max-width: 1080px) and (max-width: 1080px) {
  .modal .modal_content_wrap > .btn_group {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
.modal .modal_content_wrap header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
  margin-bottom: 24px;
  padding-bottom: 20px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #191919;
}
@media (max-width: 1080px) {
  .modal .modal_content_wrap header {
    margin-bottom: 16px;
    height: 42px;
  }
}
.modal .modal_content_wrap header h2 {
  font-size: 24px;
  font-weight: 800;
  color: #19c791;
}
@media (max-width: 1080px) {
  .modal .modal_content_wrap header h2 {
    font-size: 14px;
    font-weight: 800;
    color: #19c791;
  }
}
.modal .modal_content_wrap header .btn_group {
  width: unset;
}
.modal .modal_content_wrap header .btn_group .close {
  width: 80px !important;
  height: 36px;
}
@media (max-width: 1080px) {
  .modal .modal_content_wrap header .btn_group .close {
    padding: 0;
    width: auto !important;
    height: auto;
    font-size: 0;
    background-color: unset;
  }
  .modal .modal_content_wrap header .btn_group .close::before {
    content: "×";
    font-size: 18px;
    font-weight: 700;
    color: #191919;
  }
}
.modal#addSubjectDetail .modal_content_wrap {
  width: 1000px;
  overflow: unset;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  select {
  padding: 10px;
  width: 254px;
  height: 50px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  width: 364px;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap
  li {
  min-width: 80px;
  height: 33px;
  color: #191919;
  border: none;
  background-color: #b7b7b7;
}
@media (max-width: 1080px) {
  .modal#addSubjectDetail
    .modal_content_wrap
    table
    tbody
    tr
    td
    .cell_content_wrap
    .category_list_wrap
    li {
    min-width: unset;
  }
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap
  li.active.target_1 {
  background-color: #d0eed9;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap
  li.active.target_2 {
  background-color: #a7e7bf;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap
  li.active.target_3 {
  background-color: #72d29a;
}
.modal#addSubjectDetail
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap
  .category_list_wrap
  li.active.target_4 {
  background-color: #51c581;
}
.modal#addCertificate .modal_content_wrap table tbody tr td .cell_content_wrap {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  min-width: 200px;
}
.modal#addCertificate
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap.supporting_data
  * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal#addCertificate
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap.supporting_data
  img {
  width: 28px;
}
.modal#addCertificate
  .modal_content_wrap
  table
  tbody
  tr
  td
  .cell_content_wrap.supporting_data
  label {
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 50px;
  font-size: 0;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  cursor: pointer;
}
.modal#addHistory .modal_content_wrap {
  overflow-y: hidden;
  height: 100vh;
}
.modal#addHistory .modal_content_wrap header .invitee_code {
  font-size: 24px;
  font-weight: 700;
  color: #19c791;
}
@media (max-width: 1080px) {
  .modal#addHistory .modal_content_wrap header .invitee_code {
    margin-right: 12px;
    font-size: 14px;
  }
}
.modal#addHistory .modal_content_wrap .content_scroll_wrap {
  height: calc(100% - 174px);
  overflow: auto;
}
@media (max-width: 1080px) {
  .modal#addHistory .modal_content_wrap .content_scroll_wrap {
    height: calc(100% - 138px);
  }
}
.modal#addHistory .modal_content_wrap table .cell_content_wrap {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media (max-width: 1080px) {
  .modal#addHistory .modal_content_wrap table .cell_content_wrap {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
  }
  .modal#addHistory
    .modal_content_wrap
    table
    .cell_content_wrap
    .react-datepicker-wrapper {
    width: auto;
  }
}
.modal#addHistory
  .modal_content_wrap
  table
  .cell_content_wrap
  .is_working_wrap {
  right: 0;
  bottom: -36px;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
.modal#addHistory .modal_content_wrap .academy_search_list_wrap table {
  margin-bottom: 58px;
  border-collapse: collapse;
  text-align: center;
}
@media (max-width: 1080px) {
  .modal#addHistory .modal_content_wrap .academy_search_list_wrap table {
    margin-bottom: 24px;
  }
}
.modal#addHistory
  .modal_content_wrap
  .academy_search_list_wrap
  table
  thead
  tr
  th {
  background-color: #f0f0f0;
  padding: 12px;
}
.modal#addHistory .modal_content_wrap .academy_search_list_wrap table tbody tr {
  cursor: pointer;
}
.modal#addHistory
  .modal_content_wrap
  .academy_search_list_wrap
  table
  tbody
  tr.select {
  background-color: #f0f4f3;
}
.modal#addHistory
  .modal_content_wrap
  .academy_search_list_wrap
  table
  tbody
  tr
  th,
.modal#addHistory
  .modal_content_wrap
  .academy_search_list_wrap
  table
  tbody
  tr
  td {
  padding: 16px;
  white-space: normal;
}
.modal#agreeDetail .modal_content_wrap {
  width: 80%;
  height: 90%;
}
@media (max-width: 1080px) {
  .modal#agreeDetail .modal_content_wrap {
    min-height: var(--vh);
  }
}
.modal#agreeDetail .modal_content_wrap .content_wrap {
  padding: 24px 26px;
  width: 100%;
  height: 80%;
  margin-top: 32px;
  border: 1px solid #b4b4b4;
  overflow: auto;
  font-size: 16px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#agreeDetail .modal_content_wrap .content_wrap {
    font-size: 12px;
  }
}
@media (max-width: 1080px) {
  .modal#addTeacher .modal_content_wrap p {
    font-size: 12px;
    text-align: left;
  }
}
.modal#editSalaryInfo .modal_content_wrap table th.unit p {
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.modal#selectTime .modal_content_wrap .detail,
.modal#selectSpacialTime .modal_content_wrap .detail,
.modal#selectTeacher .modal_content_wrap .detail,
.modal#selectShuttle .modal_content_wrap .detail,
.modal#selectClassShuttle .modal_content_wrap .detail,
.modal#currentStudent .modal_content_wrap .detail,
.modal#dupClassCheck .modal_content_wrap .detail {
  text-align: center;
}
.modal#selectTime .modal_content_wrap .detail th,
.modal#selectTime .modal_content_wrap .detail td,
.modal#selectSpacialTime .modal_content_wrap .detail th,
.modal#selectSpacialTime .modal_content_wrap .detail td,
.modal#selectTeacher .modal_content_wrap .detail th,
.modal#selectTeacher .modal_content_wrap .detail td,
.modal#selectShuttle .modal_content_wrap .detail th,
.modal#selectShuttle .modal_content_wrap .detail td,
.modal#selectClassShuttle .modal_content_wrap .detail th,
.modal#selectClassShuttle .modal_content_wrap .detail td,
.modal#currentStudent .modal_content_wrap .detail th,
.modal#currentStudent .modal_content_wrap .detail td,
.modal#dupClassCheck .modal_content_wrap .detail th,
.modal#dupClassCheck .modal_content_wrap .detail td {
  padding: 10px;
}
.modal#selectTime .modal_content_wrap .detail td:last-of-type,
.modal#selectSpacialTime .modal_content_wrap .detail td:last-of-type,
.modal#selectTeacher .modal_content_wrap .detail td:last-of-type,
.modal#selectShuttle .modal_content_wrap .detail td:last-of-type,
.modal#selectClassShuttle .modal_content_wrap .detail td:last-of-type,
.modal#currentStudent .modal_content_wrap .detail td:last-of-type,
.modal#dupClassCheck .modal_content_wrap .detail td:last-of-type {
  color: #ff2222;
}
.modal#selectTime .modal_content_wrap .detail .btn_group,
.modal#selectSpacialTime .modal_content_wrap .detail .btn_group,
.modal#selectTeacher .modal_content_wrap .detail .btn_group,
.modal#selectShuttle .modal_content_wrap .detail .btn_group,
.modal#selectClassShuttle .modal_content_wrap .detail .btn_group,
.modal#currentStudent .modal_content_wrap .detail .btn_group,
.modal#dupClassCheck .modal_content_wrap .detail .btn_group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal#selectTime .modal_content_wrap {
  width: 40vw;
}
@media (max-width: 1080px) {
  .modal#selectTime .modal_content_wrap .table_scroll_wrap {
    height: unset;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
.modal#selectTime .modal_content_wrap .table_scroll_wrap p {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#selectTime .modal_content_wrap .table_scroll_wrap p {
    font-size: 12px;
    line-height: unset;
  }
}
.modal#selectTime .modal_content_wrap .select_time_wrap td ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.modal#selectTime
  .modal_content_wrap
  .select_time_wrap
  input:not([type="checkbox"]) {
  height: 40px;
}
.modal#selectTime
  .modal_content_wrap
  .select_time_wrap
  input:not([type="checkbox"])
  + .btn_group
  button {
  height: 40px;
}
.modal#selectTime .modal_content_wrap .clasroom_search_wrap .btn_group {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.modal#selectTime .modal_content_wrap .invalid {
  color: red;
}
.modal#selectTeacher .modal_content_wrap {
  width: 90vw;
  max-width: 1120px;
}
.modal#selectTeacher .modal_content_wrap header {
  margin-bottom: 52px;
}
@media (max-width: 1080px) {
  .modal#selectTeacher .modal_content_wrap header {
    margin-bottom: 24px;
  }
}
.modal#selectTeacher .modal_content_wrap .table_scroll_wrap {
  margin-top: 52px;
  height: 54vh;
}
@media (max-width: 1080px) {
  .modal#selectTeacher .modal_content_wrap .table_scroll_wrap {
    margin-top: 24px;
    height: 75vh;
  }
}
@media (max-width: 1080px) {
  .modal#selectTeacher .modal_content_wrap table.detail {
    width: 100%;
  }
}
.modal#selectTeacher .modal_content_wrap table.detail td {
  padding: 24px;
}
@media (max-width: 1080px) {
  .modal#selectTeacher .modal_content_wrap table.detail td {
    padding: 8px;
    overflow-wrap: break-word;
    word-break: keep-all;
  }
}
.modal#selectTeacher .modal_content_wrap table.detail td.subjects_available dl {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media (max-width: 1080px) {
  .modal#selectTeacher
    .modal_content_wrap
    table.detail
    td.subjects_available
    dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.modal#selectTeacher
  .modal_content_wrap
  table.detail
  td.subjects_available
  dl:not(:last-of-type) {
  margin-bottom: 12px;
}
.modal#selectTeacher
  .modal_content_wrap
  table.detail
  td.subjects_available
  dl
  dt {
  padding-right: 12px;
  width: 240px;
  overflow: hidden;
  text-align: left;
}
@media (max-width: 1080px) {
  .modal#selectTeacher
    .modal_content_wrap
    table.detail
    td.subjects_available
    dl
    dt {
    width: unset;
  }
}
@media (max-width: 1080px) {
  .modal#selectTeacher
    .modal_content_wrap
    table.detail
    td.subjects_available
    dl
    dd.level_list
    ul {
    -webkit-column-gap: 4px;
    -moz-column-gap: 4px;
    column-gap: 4px;
  }
}
@media (max-width: 1080px) {
  .modal#selectTeacher
    .modal_content_wrap
    table.detail
    td.subjects_available
    dl
    dd.level_list
    ul
    .level_item {
    width: 20px;
  }
}
.modal#selectTeacher .modal_content_wrap table.detail td .invalid {
  font-size: 16px;
  color: red;
}
@media (max-width: 1080px) {
  .modal#selectTeacher .modal_content_wrap table.detail td .invalid {
    font-size: 12px;
  }
}
.modal#selectShuttle .modal_content_wrap {
  width: 50vw;
}
.modal#selectShuttle .modal_content_wrap .desc {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
}
.modal#selectShuttle .modal_content_wrap .table_scroll_wrap {
  height: 372px;
}
.modal#selectShuttle .modal_content_wrap table.detail {
  margin-top: 48px;
  table-layout: fixed;
}
@media (max-width: 1080px) {
  .modal#selectShuttle .modal_content_wrap table.detail {
    margin-top: 24px;
  }
}
.modal#selectShuttle .modal_content_wrap table.detail thead tr.desc {
  border: none !important;
}
.modal#selectShuttle .modal_content_wrap table.detail thead tr.desc td {
  border: none !important;
}
.modal#selectShuttle .modal_content_wrap table.detail td span {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
.modal#selectClassShuttle .modal_content_wrap {
  width: 50vw;
}
.modal#selectClassShuttle .modal_content_wrap .desc {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
}
.modal#selectClassShuttle .modal_content_wrap .table_scroll_wrap {
  margin-top: 52px;
  height: 447px;
}
@media (max-width: 1080px) {
  .modal#selectClassShuttle .modal_content_wrap .table_scroll_wrap {
    margin-top: 24px;
    height: 100%;
  }
}
.modal#selectClassShuttle .modal_content_wrap .table_scroll_wrap p {
  font-size: 20px;
  font-weight: 600;
  color: inherit;
  line-height: 383px;
}
@media (max-width: 1080px) {
  .modal#selectClassShuttle .modal_content_wrap .table_scroll_wrap p {
    font-size: 12px;
    line-height: unset;
  }
}
.modal#selectClassShuttle .modal_content_wrap table.detail {
  margin-top: 52px;
}
@media (max-width: 1080px) {
  .modal#selectClassShuttle .modal_content_wrap table.detail {
    margin-top: 24px;
  }
}
.modal#selectClassShuttle .modal_content_wrap table.detail thead tr.desc {
  border: none !important;
}
.modal#selectClassShuttle .modal_content_wrap table.detail thead tr.desc td {
  border: none !important;
}
.modal#selectClassShuttle .modal_content_wrap table.detail td span {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
@media (max-width: 1080px) {
  .modal#selectClassShuttle .modal_content_wrap table.detail td span {
    white-space: normal;
    font-size: 12px;
  }
}
.modal#currentStudent header {
  position: relative;
}
.modal#currentStudent header .desc {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 700;
  color: red;
  opacity: 0.6;
}
@media (max-width: 1080px) {
  .modal#currentStudent header .desc {
    position: absolute;
    left: -8px;
    bottom: 6px;
    font-size: 12px;
  }
}
.modal#currentStudent .modal_content_wrap {
  width: 80%;
}
.modal#currentStudent .modal_content_wrap .current_student {
  margin-top: 52px;
  max-height: 400px;
  overflow-y: auto;
}
@media (max-width: 1080px) {
  .modal#currentStudent .modal_content_wrap .current_student {
    margin-top: 24px;
    max-height: 100%;
  }
}
.modal#currentStudent .modal_content_wrap .current_student p {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  text-align: center;
}
.modal#addPayment .modal_content_wrap section {
  margin-top: 52px;
  width: 100%;
}
@media (max-width: 1080px) {
  .modal#addPayment .modal_content_wrap section {
    margin-top: 0px;
  }
}
.modal#addPayment .modal_content_wrap .content_wrap {
  padding: 24px 26px;
  height: 200px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  white-space: normal;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 400;
  color: inherit;
}
@media (max-width: 1080px) {
  .modal#addPayment .modal_content_wrap .content_wrap {
    height: 100px;
    font-size: 12px;
  }
}
.modal#addPayment .modal_content_wrap h3 {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#addPayment .modal_content_wrap h3 {
    font-size: 14px;
  }
}
.modal#addReferrer .modal_content_wrap h3 {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#addReferrer .modal_content_wrap h3 {
    font-size: 14px;
  }
}
.modal#addPayment .modal_content_wrap span {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#addPayment .modal_content_wrap span {
    font-size: 12px;
  }
}
.modal#addPayment .modal_content_wrap .cell_content_wrap img {
  width: 28px;
  height: 28px;
}
@media (max-width: 1080px) {
  .modal#addPayment .modal_content_wrap .cell_content_wrap img {
    width: 20px;
    height: 20px;
  }
}
.modal#contactUs .modal_content_wrap {
  min-width: 900px;
  max-height: 80vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap {
    min-width: unset;
    max-height: unset;
  }
}
.modal#contactUs .modal_content_wrap header {
  width: 100%;
}
.modal#contactUs .modal_content_wrap > .contact_wrap {
  width: 100%;
  overflow-y: scroll;
}
.modal#contactUs .modal_content_wrap > .contact_wrap h3 {
  padding-top: 72px;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap > .contact_wrap h3 {
    padding-top: 16px;
    font-size: 20px;
  }
}
.modal#contactUs .modal_content_wrap > .contact_wrap h3 span {
  font-size: 23px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap > .contact_wrap h3 span {
    font-size: 12px;
  }
}
.modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap {
  padding: 100px 100px 0;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap {
    padding: 40px 0 0;
  }
}
.modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap * {
  font-size: 23px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap * {
    font-size: 12px;
  }
}
.modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap .desc {
  margin-top: 84px;
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 1080px) {
  .modal#contactUs .modal_content_wrap > .contact_wrap .question_wrap .desc {
    margin-top: 40px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item:not(:first-of-type) {
  margin-top: 36px;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item:not(:first-of-type) {
    margin-top: 40px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input[type="checkbox"] {
  margin-right: 16px;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input {
  margin-bottom: 16px;
  padding-left: 0;
  border: none;
  border-radius: 0;
  border-bottom: 1.5px solid #b4b4b4;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input::-webkit-input-placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #b4b4b4;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input::-moz-placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #b4b4b4;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input:-ms-input-placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #b4b4b4;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input::-ms-input-placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #b4b4b4;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #b4b4b4;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input::-moz-placeholder {
    font-size: 12px;
  }
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input:-ms-input-placeholder {
    font-size: 12px;
  }
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input::-ms-input-placeholder {
    font-size: 12px;
  }
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input::placeholder {
    font-size: 12px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input[type="checkbox"] {
  width: 22px;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input[type="checkbox"] {
    width: 12px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input[type="checkbox"]::before {
  bottom: -4px;
  width: 22px;
  height: 22px;
  background-size: 22px;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input[type="checkbox"]::before {
    width: 12px;
    height: 12px;
    bottom: -2px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  input[type="checkbox"]:checked::before {
  background: url("./assets/img/icon/ic_checkbox_checked_primary.png") no-repeat;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    input[type="checkbox"]:checked::before {
    background-size: contain;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dt {
  font-size: 23px;
  font-weight: 800;
  color: #19c791;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dt {
    font-size: 14px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd {
  position: relative;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dd.agree {
    margin-top: 16px;
    height: 20px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd.agree
  a {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-decoration: underline;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd.check_list {
  margin-top: 30px;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dd.check_list {
    margin-top: 16px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd
  strong {
  font-weight: 800;
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd
  dl {
  padding: 30px 0;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dd
    dl {
    padding: 0;
    margin-top: 24px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd
  dl
  dt {
  font-size: 23px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dd
    dl
    dt {
    font-size: 12px;
  }
}
.modal#contactUs
  .modal_content_wrap
  > .contact_wrap
  .question_wrap
  .question_item
  dl
  dd
  dl
  dd
  input {
  margin: 0;
}
@media (max-width: 1080px) {
  .modal#contactUs
    .modal_content_wrap
    > .contact_wrap
    .question_wrap
    .question_item
    dl
    dd
    dl
    dd
    input {
    height: 28px;
  }
}
.modal#boardingStudent .modal_content_wrap .table_scroll_wrap {
  margin-top: 52px;
  max-height: 274px;
}
@media (max-width: 1080px) {
  .modal#boardingStudent .modal_content_wrap .table_scroll_wrap {
    margin-top: 16px;
    max-height: unset;
  }
}
.modal#boardingStudent .modal_content_wrap .detail {
  text-align: center;
}
@media (max-width: 1080px) {
  .modal#boardingStudent .modal_content_wrap .detail {
    table-layout: fixed;
  }
}
.modal#boardingStudent .modal_content_wrap .detail th,
.modal#boardingStudent .modal_content_wrap .detail td {
  padding: 10px;
}
.modal#boardingStudent .modal_content_wrap .detail .btn_group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal#alert {
  z-index: 1000;
}
.modal#alert .modal_content_wrap {
  min-width: 300px;
  max-width: 480px;
  text-align: center;
  border-radius: 12px;
}
@media (max-width: 1080px) {
  .modal#alert .modal_content_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: fixed;
    width: calc(100vw - 48px) !important;
    height: auto;
  }
}
.modal#alert .modal_content_wrap h1 {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 32px;
  width: 72px;
  height: 72px;
  border: 4px solid #19c791;
  border-radius: 36px;
  color: #19c791;
  font-size: 32px;
  font-weight: 1000;
  color: #19c791;
  opacity: 0.6;
}
@media (max-width: 1080px) {
  .modal#alert .modal_content_wrap h1 {
    margin-bottom: 24px;
    width: 52px;
    height: 52px;
    border: 3px solid #19c791;
    font-size: 24px;
    font-weight: 1000;
    color: #19c791;
  }
}
.modal#alert .modal_content_wrap h1.alert {
  color: #d6d6d6;
  border-color: #d6d6d6;
}
.modal#alert .modal_content_wrap .text {
  margin-bottom: 32px;
}
@media (max-width: 1080px) {
  .modal#alert .modal_content_wrap .text {
    margin-bottom: 24px;
  }
}
.modal#alert .modal_content_wrap span {
  font-size: 18px;
  font-weight: 400;
  color: inherit;
  line-height: 28px;
}
@media (max-width: 1080px) {
  .modal#alert .modal_content_wrap span {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
.modal#selectAcademy .modal_content_wrap .table_scroll_wrap,
.modal#createAcademyList .modal_content_wrap .table_scroll_wrap {
  max-height: 367px;
  overflow-y: auto;
}
.modal#selectAcademy .modal_content_wrap .table_scroll_wrap .detail th,
.modal#selectAcademy .modal_content_wrap .table_scroll_wrap .detail td,
.modal#createAcademyList .modal_content_wrap .table_scroll_wrap .detail th,
.modal#createAcademyList .modal_content_wrap .table_scroll_wrap .detail td {
  text-align: center;
  text-overflow: unset;
}
.modal#selectAcademy .modal_content_wrap .table_scroll_wrap .detail th,
.modal#createAcademyList .modal_content_wrap .table_scroll_wrap .detail th {
  padding-right: 20px;
}
@media (max-width: 1080px) {
  .modal#selectAcademy .modal_content_wrap .table_scroll_wrap .detail th,
  .modal#createAcademyList .modal_content_wrap .table_scroll_wrap .detail th {
    padding-right: 0;
  }
}
.modal#selectAcademy .modal_content_wrap .table_scroll_wrap .detail td,
.modal#createAcademyList .modal_content_wrap .table_scroll_wrap .detail td {
  padding: 12px 8px;
}
.modal#selectAcademy
  .modal_content_wrap
  .table_scroll_wrap
  .detail
  td.academy_address,
.modal#createAcademyList
  .modal_content_wrap
  .table_scroll_wrap
  .detail
  td.academy_address {
  white-space: nowrap;
  overflow-x: scroll;
}
.modal[id^="eventPopup"] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  .modal[id^="eventPopup"] {
    position: absolute;
  }
}
.modal[id^="eventPopup"]:not(:first-child)::before {
  background-color: unset;
  z-index: 1;
}
.modal[id^="eventPopup"] .modal_content_wrap {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: unset;
  transform: unset;
}
@media (max-width: 1080px) {
  .modal[id^="eventPopup"] .modal_content_wrap {
    width: 88vw !important;
    height: auto;
    border-radius: 10px;
  }
}
.modal[id^="eventPopup"] .modal_content_wrap header input::before {
  bottom: -4px;
}
@media (max-width: 1080px) {
  .modal[id^="eventPopup"] .modal_content_wrap header input::before {
    bottom: -1px;
  }
}
.modal[id^="eventPopup"] .modal_content_wrap .event_content {
  width: 400px;
}
@media (max-width: 1080px) {
  .modal[id^="eventPopup"] .modal_content_wrap .event_content {
    width: 100%;
  }
}
.modal[id^="eventPopup"] .modal_content_wrap .event_content img {
  width: 100%;
}
.modal.select .modal_content_wrap .select_time_wrap .select_day_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}

#viewFullImage {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: 90vh;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #333;
  z-index: 100;
}
#viewFullImage button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  font-size: 40px;
  color: #fff;
}
#viewFullImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}
.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 10 !important;
}
.react-datepicker__tab-loop .react-datepicker__navigation {
  top: 11px;
}
.react-datepicker__tab-loop
  .react-datepicker__header__dropdown
  .react-datepicker__year-read-view--down-arrow {
  top: 5px;
}
.react-datepicker__tab-loop
  .react-datepicker__header__dropdown
  .react-datepicker__year-dropdown {
  top: 44px;
  left: 24%;
  width: 56%;
  max-height: 200px;
  overflow-y: scroll;
}
@media (max-width: 1080px) {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
.category_list_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 4px;
}
@media (max-width: 1080px) {
  .category_list_wrap {
    row-gap: 9px;
  }
}
.category_list_wrap.slim li {
  width: 80px;
  height: 33px;
}
@media (max-width: 1080px) {
  .category_list_wrap.slim li {
    width: 60px;
  }
}
.category_list_wrap.jc_fs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.category_list_wrap.gap_4 {
  -webkit-column-gap: 4px !important;
  -moz-column-gap: 4px !important;
  column-gap: 4px !important;
}
.category_list_wrap.gap_8 {
  -webkit-column-gap: 8px !important;
  -moz-column-gap: 8px !important;
  column-gap: 8px !important;
}
.category_list_wrap.gap_12 {
  -webkit-column-gap: 12px !important;
  -moz-column-gap: 12px !important;
  column-gap: 12px !important;
}
.category_list_wrap.gap_16 {
  -webkit-column-gap: 16px !important;
  -moz-column-gap: 16px !important;
  column-gap: 16px !important;
}
.category_list_wrap.gap_20 {
  -webkit-column-gap: 20px !important;
  -moz-column-gap: 20px !important;
  column-gap: 20px !important;
}
.category_list_wrap.gap_24 {
  -webkit-column-gap: 24px !important;
  -moz-column-gap: 24px !important;
  column-gap: 24px !important;
}
.category_list_wrap.gap_28 {
  -webkit-column-gap: 28px !important;
  -moz-column-gap: 28px !important;
  column-gap: 28px !important;
}
.category_list_wrap.gap_32 {
  -webkit-column-gap: 32px !important;
  -moz-column-gap: 32px !important;
  column-gap: 32px !important;
}
.category_list_wrap.gap_36 {
  -webkit-column-gap: 36px !important;
  -moz-column-gap: 36px !important;
  column-gap: 36px !important;
}
.category_list_wrap.gap_40 {
  -webkit-column-gap: 40px !important;
  -moz-column-gap: 40px !important;
  column-gap: 40px !important;
}
.category_list_wrap li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 18px;
  font-weight: 400;
  color: #626262;
  width: 60px;
  height: 50px;
  border-radius: 10px;
  border: 1.5px solid #19c791;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .category_list_wrap li {
    width: calc(25% - 6px);
    font-size: 14px;
    font-weight: 400;
    color: #626262;
  }
}
.category_list_wrap li.active {
  border: none;
  background-color: #19c791;
  color: #fff;
}

.post_wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  padding-bottom: 18px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 480px !important;
  max-width: 100vw;
  height: auto !important;
  background-color: #fff;
}
@media (max-width: 1080px) {
  .post_wrap * {
    font-size: 12px;
  }
}
.post_wrap::before {
  display: inline-block;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100vw;
  height: 200vh;
  background-color: transparent;
  content: "";
}
.post_wrap .btn_close {
  position: absolute;
  top: -20px;
  right: 0;
  width: 21px;
  height: 21px;
  background-color: #939393;
  outline: none;
  border: none;
  z-index: 1;
  cursor: pointer;
}
.post_wrap .btn_close::after,
.post_wrap .btn_close::before {
  position: absolute;
  top: calc(50% - 0.5px);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  content: "";
}
.post_wrap .btn_close::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.post_wrap .btn_close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.post_wrap .find_post {
  width: 100%;
  border: 1px solid;
}

.selectbox_wrap {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  padding: 10px;
  width: 254px;
  max-width: 100%;
  height: 50px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .selectbox_wrap {
    max-width: 100%;
    height: 40px;
  }
}
.selectbox_wrap * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  .selectbox_wrap * {
    font-size: 14px;
  }
}
.selectbox_wrap.full {
  width: 100%;
}
.selectbox_wrap.medium {
  width: 443px;
}
.selectbox_wrap .current_value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
}
.selectbox_wrap .current_value img {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
@media (max-width: 1080px) {
  .selectbox_wrap .current_value img {
    width: 12px;
  }
}
.selectbox_wrap .current_value.open img {
  -webkit-transform: rotate(180deg) translate(0, 50%);
  transform: rotate(180deg) translate(0, 50%);
}
.selectbox_wrap .current_value.read_only {
  background-color: #e4e4e4;
}
.selectbox_wrap .etc_value {
  position: absolute;
  left: 0;
  top: 110%;
  display: block !important;
  text-align: left;
  width: 100%;
  max-height: 200px;
  border: 1.5px solid #b4b4b4;
  background-color: #fff;
  overflow: auto;
  z-index: 10;
}
@media (max-width: 1080px) {
  .selectbox_wrap .etc_value {
    min-width: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}
.selectbox_wrap .etc_value li {
  padding: 10px;
  cursor: pointer;
}

[class^="time_input_wrap"] {
  position: relative;
}
[class^="time_input_wrap"] input {
  position: relative;
  height: 50px;
  border: 1.5px solid #b4b4b4;
  border-radius: 10px;
  cursor: pointer;
}
[class^="time_input_wrap"] input:-moz-read-only {
  background-color: transparent !important;
}
[class^="time_input_wrap"] input:read-only {
  background-color: transparent !important;
}
[class^="time_input_wrap"] img {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
}
[class^="time_input_wrap"] .open img {
  -webkit-transform: rotate(180deg) translate(0, 50%);
  transform: rotate(180deg) translate(0, 50%);
}
[class^="time_input_wrap"] .time_list_wrap {
  position: absolute;
  top: 110%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  border: 1.5px solid #b4b4b4;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
[class^="time_input_wrap"] .time_list_wrap ul {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
[class^="time_input_wrap"] .time_list_wrap ul li {
  width: 100%;
  cursor: pointer;
}
[class^="time_input_wrap"] .time_list_wrap ul li.select {
  color: #fff;
  background-color: #191919;
}
[class^="time_input_wrap"] .time_list_wrap ul li.disabled {
  color: #b7b7b7;
}
[class^="time_input_wrap"] .time_list_wrap .hour_list_wrap,
[class^="time_input_wrap"] .time_list_wrap .minute_list_wrap {
  width: 50%;
}
[class^="time_input_wrap"] .time_list_wrap .hour_list_wrap li,
[class^="time_input_wrap"] .time_list_wrap .minute_list_wrap li {
  text-align: center;
}
[class^="time_input_wrap"] .time_list_wrap .hour_list_wrap li ul,
[class^="time_input_wrap"] .time_list_wrap .minute_list_wrap li ul {
  height: 180px;
  overflow-y: scroll;
}
[class^="time_input_wrap"] .time_list_wrap .hour_list_wrap li ul li,
[class^="time_input_wrap"] .time_list_wrap .minute_list_wrap li ul li {
  position: relative;
  padding: 4px 0;
}
[class^="time_input_wrap"] .time_list_wrap .hour_list_wrap strong,
[class^="time_input_wrap"] .time_list_wrap .minute_list_wrap strong {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 0;
  border-bottom: 1px solid #b4b4b4;
}

.loading_wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.loading_wrap img {
  width: 300px;
  height: 300px;
}
@media (max-width: 1080px) {
  .loading_wrap img {
    width: 200px;
    height: 200px;
  }
}

.region_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  margin-top: 30px;
}
@media (max-width: 1080px) {
  .region_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 12px;
  }
}
.region_wrap .selectbox_wrap {
  width: 214px;
}
@media (max-width: 1080px) {
  .region_wrap .selectbox_wrap {
    width: 100%;
  }
}

#intro.desktop {
  padding: 0;
  background-color: #fff;
}
#intro.desktop .intro_wrap .intro_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 130px;
  -moz-column-gap: 130px;
  column-gap: 130px;
  height: calc(100vh - 351px);
  min-height: 500px;
  background-color: #19c791;
  border-bottom-left-radius: 160px;
  color: #fff;
}
#intro.desktop .intro_wrap .intro_main .intro_tit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .title {
  font-size: 68px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 9px;
  line-height: 85px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .top_title {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 7px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .top_title .strong {
  font-weight: 700;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .sub_title {
  font-size: 29px;
  font-weight: 500;
  color: #191919;
  margin-bottom: 28px;
  font-family: "esamanru";
  line-height: 30px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .btn_group {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .btn_group .login_btn {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  padding: 16px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 31.5px;
}
#intro.desktop .intro_wrap .intro_main .intro_tit .btn_group .sign_btn {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 25px;
  font-weight: 700;
  color: #19c791;
  padding: 16px;
  background-color: #fff;
  border: 2px solid #15a97b;
  border-radius: 31.5px;
}
#intro.desktop .intro_wrap .intro_main .intro_func img {
  width: 30vw;
  max-width: 443px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list {
  padding-top: 62px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list.floating {
  position: fixed;
  top: 0;
  padding: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 1;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list.floating ul {
  height: auto;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list.floating ul li p {
  margin: 0;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list.floating ul li .img_wrap {
  display: none;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list.floating .intro_con_desc {
  display: none;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list .intro_con_desc {
  height: 84px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list .intro_con_desc strong {
  font-size: 36px;
  font-weight: 800;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 40px calc((100vw - 1106px) / 2) 40px;
  height: 224px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li {
  cursor: pointer;
  width: 138px;
  line-height: 30px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li p {
  position: relative;
  margin-top: 16px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li .img_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 auto;
  width: 92px;
  height: 92px;
  border-radius: 20px;
  background-color: #8de1c6;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li .img_wrap img {
  width: 56px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li.active .img_wrap {
  background-color: #19c791;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list ul li.active p::before {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 5px;
  background-color: #19c791;
  content: "";
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list .intro_con_desc_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
  padding: 12px calc((100vw - 1106px) / 2);
  background-color: #f4f8f6;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_list .intro_con_desc_sub img {
  width: 72px;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_tab_list
  .intro_con_desc_sub
  .bubble {
  padding-left: 50px;
  width: 503px;
  height: 56px;
  border-radius: 19px;
  font-size: 18px;
  font-weight: 700;
  color: #191919;
  line-height: 56px;
  background: url("./assets/img/intro_bubble.png") center center/503px no-repeat;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con {
  position: relative;
  padding: 100px calc((100vw - 17px - 1106px) / 2) 120px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header h3 {
  font-size: 20px;
  font-weight: 800;
  color: #19c791;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header h3 strong {
  display: block;
  margin-top: 8px;
  font-size: 32px;
  font-weight: 800;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header p {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 400;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header ul {
  margin-top: 24px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 10px;
  width: 443px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  border-radius: 10px;
  background-color: #f4f4f4;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_tab_con
  header
  ul
  li:first-of-type {
  margin-bottom: 16px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con header ul img {
  margin-right: 16px;
  width: 28px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con > img {
  display: block;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con > img.right {
  margin: 0 !important;
  position: absolute;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con > hr {
  position: absolute;
  border: none;
  top: -214px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con1 > img {
  margin-top: 36px;
  width: 539px;
  height: 326px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con1 > img.right {
  width: 538px;
  bottom: 120px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con2 > img {
  margin-top: 36px;
  width: 100%;
  height: 326px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con2 > img.right {
  width: 443px;
  height: 291px;
  top: 100px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con3 > img {
  margin-top: 54px;
  width: 538px;
  height: 323px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con3 > img.right {
  width: 538px;
  height: 667px;
  bottom: 120px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con4 > img {
  margin-top: 36px;
  width: 100%;
  height: 364px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con5 > img {
  margin-top: 54px;
  width: 584px;
  height: 364px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con5 > img.right {
  width: 493px;
  height: 589px;
  bottom: 120px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con6 > img {
  margin-top: 36px;
  width: 820px;
  height: 364px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con6 > img.right {
  width: 538px;
  height: 291px;
  top: 100px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con7 > img {
  margin-top: 36px;
  width: 100%;
  height: 364px;
}
#intro.desktop .intro_wrap .intro_sub .intro_tab_con.con7 > img.right {
  width: 371px;
  height: 293px;
  top: 100px;
  right: calc((100vw - 17px - 1106px) / 2);
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 {
  padding: 92px 0;
  position: relative;
  background-color: #f4f8f6;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 h1 {
  text-align: center;
  font-size: 23px;
  font-weight: 400;
  color: #191919;
  line-height: 1.5;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 h1 strong {
  display: block;
  font-size: 35px;
  font-weight: 800;
  color: #191919;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  margin-top: 52px;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap .bg {
  padding: 18px 0;
  width: 538px;
  height: 482px;
  border-radius: 18px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap .bg:first-of-type {
  background-color: #19c791;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap .bg:last-of-type {
  background-color: #ffac4b;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap .bg .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0 60px;
  height: 100%;
  background-color: #fff;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .img_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 210px;
  border-bottom: 1.5px solid #d8d8d8;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .img_wrap
  img {
  width: 108px;
  height: 120px;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .text_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .text_wrap
  p {
  font-size: 23px;
  font-weight: 700;
  color: #191919;
  text-align: center;
  line-height: 35px;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .text_wrap
  p
  strong {
  font-size: 25px;
  font-weight: 800;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .text_wrap
  p
  strong.ev {
  color: #19c791;
}
#intro.desktop
  .intro_wrap
  .intro_sub
  .intro_con.con1
  .wrap
  .bg
  .item
  .text_wrap
  p
  strong.mv {
  color: #ff9f2f;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con1 .wrap .plus {
  position: absolute;
  width: 78px;
  height: 78px;
  border-radius: 39px;
  text-align: center;
  line-height: 78px;
  font-size: 70px;
  font-weight: 700;
  color: #a1a1a1;
  background-color: #e9e9e9;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 40px calc((100vw - 1106px) / 2);
  background-color: #19c791;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con2 p {
  line-height: 40px;
  font-family: "esamanru";
  font-size: 25px;
  font-weight: 300;
  color: #fff;
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con2 p strong {
  font-size: 30px;
  font-weight: 700;
  color: #ffed4b;
  font-family: "esamanru";
}
#intro.desktop .intro_wrap .intro_sub .intro_con.con2 button {
  width: 304px;
  height: 74px;
  border-radius: 37px;
  font-size: 23px;
  font-weight: 700;
  color: #19c791;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#intro.desktop .intro_wrap footer {
  padding: 56px calc((100vw - 1106px) / 2) 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#intro.desktop .intro_wrap footer .company_info_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#intro.desktop .intro_wrap footer .company_info_wrap h1 {
  margin-bottom: 40px;
  font-size: 23px;
  font-weight: 700;
  color: #191919;
}
#intro.desktop .intro_wrap footer .company_info_wrap p {
  font-size: 18px;
  font-weight: 300;
  color: #191919;
  line-height: 30px;
}
#intro.desktop .intro_wrap footer .company_info_wrap p span {
  text-decoration: underline;
}
#intro.desktop .intro_wrap footer .company_info_wrap ul {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 80px;
  -moz-column-gap: 80px;
  column-gap: 80px;
}
#intro.desktop .intro_wrap footer .company_info_wrap ul li {
  font-size: 18px;
  font-weight: 300;
  color: #696969;
}
#intro.desktop .intro_wrap footer .sns_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
#intro.desktop .intro_wrap footer .sns_wrap ul li a img {
  width: 50px;
}
#intro.desktop .intro_wrap footer .sns_wrap ul li a img:last-of-type {
  display: none;
}
#intro.desktop .intro_wrap footer .sns_wrap ul li:hover a img:first-of-type {
  display: none;
}
#intro.desktop .intro_wrap footer .sns_wrap ul li:hover a img:last-of-type {
  display: inline;
}

#intro.mobile {
  padding: 0;
  height: var(--vh);
  overflow: hidden;
}
#intro.mobile .intro_wrap {
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  height: 100%;
}
#intro.mobile .intro_wrap .intro_main {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 32px 24px 0;
  width: 100vw;
  height: var(--vh);
  background-color: #19c791;
  border-bottom-left-radius: 16vw;
}
#intro.mobile .intro_wrap .intro_main .intro_tit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  max-height: 480px;
  text-align: center;
}
#intro.mobile .intro_wrap .intro_main .intro_tit img.icon {
  width: 22.22vw;
  max-width: 132px;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .arrow_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .arrow_wrap img.arrow {
  width: 23px;
  height: 25px;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .top_title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .title {
  margin-top: 12px;
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .sub_title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: inherit;
  font-family: "esamanru";
}
#intro.mobile .intro_wrap .intro_main .intro_tit .btn_group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 40px;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .btn_group button {
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 800;
  color: #19c791;
  border-radius: 10px;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .btn_group button.login_btn {
  background-color: #fff;
}
#intro.mobile .intro_wrap .intro_main .intro_tit .btn_group button.sign_btn {
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  border: 1px solid #fff;
  background-color: #0db27f;
}
#intro.mobile .intro_wrap .intro_sub .mySwiper {
  height: var(--vh);
  background-color: #fff;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 24px;
  height: var(--vh);
  text-align: center;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con h3 {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con h3 strong {
  font-weight: 1000;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con .bar {
  margin: 16px auto;
  width: 2px;
  height: 14px;
  background-color: #dee6e4;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con p {
  margin-bottom: 16px;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con p img {
  margin-bottom: 6px;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con img {
  max-width: 100%;
  max-height: calc(var(--vh) - 220px);
  -o-object-fit: contain;
  object-fit: contain;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con.intro_service_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  text-align: center;
}
#intro.mobile
  .intro_wrap
  .intro_sub
  .intro_tab_con.intro_service_list
  header
  h3 {
  font-size: 18px;
  font-weight: 700;
  color: inherit;
}
#intro.mobile
  .intro_wrap
  .intro_sub
  .intro_tab_con.intro_service_list
  header
  h3
  strong {
  font-size: 24px;
  font-weight: 1000;
  color: inherit;
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con.intro_service_list > img {
  height: calc(100% - 340px);
}
#intro.mobile .intro_wrap .intro_sub .intro_tab_con.intro_service_list img {
  max-height: 60%;
}
#intro.mobile
  .intro_wrap
  .intro_sub
  .intro_tab_con.intro_service_list
  img.speech_bubble {
  max-width: 252px;
}
#intro.mobile
  .intro_wrap
  .intro_sub
  .intro_tab_con.intro_service_list
  .btn_group
  button {
  height: 48px;
  font-size: 16px;
}
#intro.mobile .swiper-pagination-horizontal {
  bottom: 10%;
}
#intro.mobile .swiper-pagination-bullet-active {
  background-color: #19c791;
}

.event_popup_wrap {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
.event_popup_wrap:empty {
  display: none;
}

#login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
}
@media (max-width: 1080px) {
  #login {
    display: block;
  }
}
#login .left_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 50%;
  min-width: 500px;
  font-family: "esamanru";
  background-color: #19c791;
}
#login .left_section .logo {
  width: 107px;
  margin-bottom: 19px;
}
#login .left_section .title {
  font-size: 56px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 8px;
  line-height: 65px;
}
#login .left_section .sub_title {
  font-size: 24px;
  font-weight: 500;
  color: #191919;
  font-family: "esamanru";
  line-height: 25px;
}
#login .right_section {
  width: 50%;
  min-width: 500px;
  padding: 40px 130px;
}
@media (max-width: 1080px) {
  #login .right_section {
    width: 100%;
    height: auto;
    min-width: unset;
    padding: 36px 24px;
  }
}
#login .right_section .title {
  margin-bottom: 72px;
  font-size: 57px;
  font-weight: 500;
  color: #19c791;
  line-height: 55px;
  text-align: center;
}
@media (max-width: 1080px) {
  #login .right_section .title {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 500;
    color: #19c791;
  }
}
#login .right_section .input_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 33px;
}
@media (max-width: 1080px) {
  #login .right_section .input_wrap {
    margin-bottom: 28px;
  }
}
#login .right_section .input_wrap .password_wrap {
  position: relative;
  width: 100%;
}
#login .right_section .input_wrap .password_wrap .show_pw {
  position: absolute;
  top: 50%;
  right: 9px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
}
@media (max-width: 1080px) {
  #login .right_section .input_wrap .password_wrap .show_pw {
    width: 24px;
    height: 24px;
  }
}
#login .right_section .input_wrap_tit {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  margin-bottom: 12px;
  line-height: 24px;
}
@media (max-width: 1080px) {
  #login .right_section .input_wrap_tit {
    font-size: 14px;
    font-weight: 700;
    color: inherit;
  }
}
#login .right_section .input_wrap input {
  font-size: 18px;
  font-weight: 400;
  color: #505050;
  padding: 14px 10px;
  line-height: 20px;
}
@media (max-width: 1080px) {
  #login .right_section .input_wrap input {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
#login .right_section .btn_group {
  margin-top: 36px;
}
#login .right_section .btn_find_pw,
#login .right_section .btn_sign_up {
  font-size: 14px;
  font-weight: 700;
  color: #505050;
  margin-top: 9px;
  margin-left: auto;
  text-decoration: underline;
}
@media (max-width: 1080px) {
  #login .right_section .btn_find_pw,
  #login .right_section .btn_sign_up {
    font-size: 10px;
    font-weight: 700;
    color: inherit;
  }
}
@media (max-width: 1080px) {
  #login .right_section .btn_find_pw {
    position: absolute;
    right: 0;
    bottom: -22px;
  }
}
#login .right_section .btn_sign_up {
  display: block;
  margin-top: 68px;
  text-align: center;
}
@media (max-width: 1080px) {
  #login .right_section .btn_sign_up {
    margin-top: 36px;
  }
}

#joinStep1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
}
@media (max-width: 1080px) {
  #joinStep1 {
    padding: 0 24px;
  }
}
#joinStep1 .top_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  height: 372px;
  background-color: #19c791;
}
#joinStep1 .top_section * {
  font-family: "esamanru";
}
@media (max-width: 1080px) {
  #joinStep1 .top_section {
    height: auto;
    background-color: #fff;
  }
}
#joinStep1 .top_section img {
  margin-bottom: 24px;
  width: 140px;
  height: 108px;
}
@media (max-width: 1080px) {
  #joinStep1 .top_section img {
    margin-top: 72px;
    width: 80px;
    height: 62px;
  }
}
#joinStep1 .top_section h1 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
#joinStep1 .top_section h1 strong {
  color: #ffd300;
}
@media (max-width: 1080px) {
  #joinStep1 .top_section h1 {
    display: none;
  }
}
#joinStep1 .pending_nice {
  padding-top: 60px;
}
@media (max-width: 1080px) {
  #joinStep1 .pending_nice {
    padding-top: 40px;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#joinStep1 .input_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-top: 60px;
  min-width: 538px;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
@media (max-width: 1080px) {
  #joinStep1 .input_group {
    padding: 0px;
    min-width: unset;
    padding-top: 0;
    -webkit-column-gap: 28px;
    -moz-column-gap: 28px;
    column-gap: 28px;
  }
}
#joinStep1 .input_group dl {
  width: 100%;
}
#joinStep1 .input_group dl.agree dd input:not([type="checkbox"]) {
  width: auto;
}
#joinStep1 .input_group dl.agree dd input[type="checkbox"]::before {
  bottom: -9px;
}
@media (max-width: 1080px) {
  #joinStep1 .input_group dl.agree dd input[type="checkbox"]::before {
    bottom: -6px;
  }
}
#joinStep1 .btn_group.join {
  padding: 24px 0 64px;
}
@media (max-width: 1080px) {
  #joinStep1 .btn_group.join {
    padding-top: 28px;
  }
}

#joinStep2 section h1,
#joinStep2 section p,
#joinStep2 section dd {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
#joinStep2 section header {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #191919;
}
@media (max-width: 1080px) {
  #joinStep2 section header {
    margin: 0;
  }
}
#joinStep2 section header h1 {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 700;
  color: inherit;
  font-family: "NanumSquare", sans-serif;
}
@media (max-width: 1080px) {
  #joinStep2 section header h1 {
    font-size: 16px;
    font-weight: 700;
    color: inherit;
  }
}
@media (max-width: 1080px) {
  #joinStep2 section header p {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#joinStep2 section .btn_group.join_btn {
  margin: 32px 0;
}
@media (max-width: 1080px) {
  #joinStep2 section .btn_group.join_btn {
    margin: 16px 0;
  }
}
#joinStep2 section .btn_group .btn_default {
  font-size: 18px;
  font-weight: 700;
  color: #191919;
}
#joinStep2 section .btn_group .btn_default.guide {
  height: 32px;
}
@media (max-width: 1080px) {
  #joinStep2 section .btn_group .btn_default {
    font-size: 14px;
    font-weight: 700;
    color: inherit;
  }
}
@media (max-width: 1080px) {
  #joinStep2 section dl dd {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#joinStep2 section dl dd:first-of-type {
  margin-bottom: 6px;
}

#joinCreate {
  padding-top: 60px;
}
@media (max-width: 1080px) {
  #joinCreate {
    padding-top: 40px;
  }
}
#joinCreate .input_group dl.agree dd input:not([type="checkbox"]) {
  width: auto;
}
#joinCreate .input_group dl.agree dd input[type="checkbox"]::before {
  bottom: -9px;
}
#joinCreate .sumbit {
  padding: 28px 0 52px;
}
@media (max-width: 1080px) {
  #joinCreate .sumbit {
    padding: 0;
  }
}

#joinCreateConfirm,
#joinCreateHold {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  #joinCreateConfirm,
  #joinCreateHold {
    padding: 90px 24px 32px;
  }
}
@media (max-width: 1080px) {
  #joinCreateConfirm section,
  #joinCreateHold section {
    width: 100%;
  }
}
#joinCreateConfirm section figure,
#joinCreateHold section figure {
  position: relative;
  margin-bottom: 64px;
  padding: 36px 116px 56px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure,
  #joinCreateHold section figure {
    margin-bottom: 24px;
    padding: 32px 0;
    border-radius: 16px;
  }
}
#joinCreateConfirm section figure header h1,
#joinCreateHold section figure header h1 {
  text-align: center;
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure header h1,
  #joinCreateHold section figure header h1 {
    font-size: 20px;
    font-weight: 700;
    color: #19c791;
  }
  #joinCreateConfirm section figure header h1 strong,
  #joinCreateHold section figure header h1 strong {
    font-size: 20px;
    font-weight: 700;
    color: #15966e;
  }
}
#joinCreateConfirm section figure table,
#joinCreateHold section figure table {
  color: #191919;
  text-align: left;
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure table,
  #joinCreateHold section figure table {
    padding: 0 12px !important;
    border-spacing: 12px !important;
  }
}
#joinCreateConfirm section figure table tr th,
#joinCreateConfirm section figure table tr td,
#joinCreateHold section figure table tr th,
#joinCreateHold section figure table tr td {
  white-space: normal;
}
#joinCreateConfirm section figure table tr th,
#joinCreateHold section figure table tr th {
  width: 160px;
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure table tr th,
  #joinCreateHold section figure table tr th {
    font-size: 14px;
    font-weight: 700;
    color: inherit;
  }
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure table tr td,
  #joinCreateHold section figure table tr td {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#joinCreateConfirm section figure img,
#joinCreateHold section figure img {
  position: absolute;
  right: 17px;
  bottom: 17px;
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure img,
  #joinCreateHold section figure img {
    width: 66px;
  }
}

#joinCreateConfirm {
  padding-bottom: 40px;
}
@media (max-width: 1080px) {
  #joinCreateConfirm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
#joinCreateConfirm header {
  margin-bottom: 36px;
}
@media (max-width: 1080px) {
  #joinCreateConfirm header {
    margin-bottom: 24px;
  }
}
#joinCreateConfirm header * {
  font-family: "esamanru";
}
#joinCreateConfirm header h1 {
  font-size: 40px;
  font-weight: 700;
  color: #19c791;
}
#joinCreateConfirm header h1 strong {
  font-size: 40px;
  font-weight: 700;
  color: #15966e;
}
@media (max-width: 1080px) {
  #joinCreateConfirm header h1 {
    font-size: 20px;
    font-weight: 700;
    color: #19c791;
  }
  #joinCreateConfirm header h1 strong {
    font-size: 20px;
    font-weight: 700;
    color: #15966e;
  }
}
#joinCreateConfirm section figure table {
  border-spacing: 24px;
}
@media (max-width: 1080px) {
  #joinCreateConfirm section figure table tr th {
    width: 80px;
  }
}
#joinCreateConfirm .btn_group button {
  margin: 0 auto;
}

#joinCreateHold figure header {
  margin-bottom: 48px;
}
@media (max-width: 1080px) {
  #joinCreateHold figure header {
    margin-bottom: 24px;
  }
}
#joinCreateHold figure header h1 span {
  display: inline-block;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: #ff2222;
  line-height: 28px;
}
#joinCreateHold figure header h1 span strong {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  color: #ff2222;
  line-height: 22px;
}
@media (max-width: 1080px) {
  #joinCreateHold figure header h1 span {
    font-size: 10px;
    font-weight: 400;
    color: #ff2222;
    line-height: 14px;
  }
  #joinCreateHold figure header h1 span strong {
    font-size: 14px;
    font-weight: 700;
    color: #ff2222;
    line-height: 14px;
  }
}
#joinCreateHold figure table {
  border-spacing: 30px 12px;
}
#joinCreateHold figure table tr th {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
#joinCreateHold figure table tr td input {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #joinCreateHold figure table tr td input {
    font-size: 12px;
    font-weight: 400;
    color: #191919;
  }
}
#joinCreateHold figure > .btn_group {
  margin-top: 58px;
  padding: 0 30px;
}
@media (max-width: 1080px) {
  #joinCreateHold figure > .btn_group {
    margin-top: 24px;
    padding: 0 24px;
  }
}
#joinRegistration .join_invitee_wrap {
  width: 500px;
  text-align: center;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap {
    width: unset;
  }
}
#joinRegistration .join_invitee_wrap .invitee_code_wrap header {
  margin-bottom: 84px;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_code_wrap header {
    margin-bottom: 40px;
  }
}
#joinRegistration .join_invitee_wrap .invitee_code_wrap header h1 {
  margin-bottom: 40px;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_code_wrap header h1 {
    margin-bottom: 24px;
  }
}
#joinRegistration .join_invitee_wrap .invitee_code_wrap header figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_code_wrap header figure {
    -webkit-column-gap: 4px;
    -moz-column-gap: 4px;
    column-gap: 4px;
  }
}
#joinRegistration .join_invitee_wrap .invitee_code_wrap header figure label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  font-family: "esamanru";
  font-size: 24px;
  font-weight: 700;
  color: #15966e;
  border: 1.5px solid #19c791;
  background-color: #fff;
  cursor: pointer;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_code_wrap header figure label {
    width: 48px;
    height: 48px;
    font-size: 18px;
  }
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap {
  visibility: hidden;
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap.pass {
  visibility: visible;
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header {
  padding: 24px;
  border-radius: 20px;
  background-color: #f1f1f1;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header * {
  font-family: "esamanru";
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header h1 {
  font-size: 20px;
  font-weight: 500;
  color: #191919;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header h1 {
    font-size: 16px;
    font-weight: 500;
    color: #191919;
  }
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header p {
  margin-top: 24px;
  font-size: 30px;
  font-weight: 700;
  color: #15966e;
}
@media (max-width: 1080px) {
  #joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header p {
    margin-top: 12px;
    font-size: 20px;
  }
}
#joinRegistration .join_invitee_wrap .invitee_academy_info_wrap header p span {
  font-size: 20px;
  font-weight: 500;
  color: #191919;
}
@media (max-width: 1080px) {
  #joinRegistration
    .join_invitee_wrap
    .invitee_academy_info_wrap
    header
    p
    span {
    font-size: 16px;
  }
}

#findPw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#findPw > section {
  text-align: center;
}
#findPw > section header {
  margin-bottom: 40px;
}
@media (max-width: 1080px) {
  #findPw > section header {
    margin-bottom: 24px;
  }
}
#findPw > section header h1 {
  font-family: "esamanru";
  font-size: 40px;
  font-weight: 700;
  color: #19c791;
}
@media (max-width: 1080px) {
  #findPw > section header h1 {
    font-size: 24px;
    font-weight: 700;
    color: #19c791;
  }
}
#findPw > section section p {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  #findPw > section section p {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #191919;
  }
}
#findPw > section section p span {
  color: #197edc;
}
#findPw > section section .line {
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #findPw > section section .line {
    font-size: 14px;
    font-weight: 400;
    color: #191919;
  }
}

@media (max-width: 1080px) {
  #changePw section {
    width: 100%;
  }
}
#changePw section header {
  margin-bottom: 36px;
}
#changePw section header h1 {
  text-align: center;
  font-family: "esamanru";
  font-size: 40px;
  font-weight: 700;
  color: #19c791;
}
@media (max-width: 1080px) {
  #changePw section header h1 {
    font-size: 24px;
    font-weight: 700;
    color: #19c791;
  }
}
#changePw h1 {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 700;
  color: #19c791;
}
#changePw .btn_group {
  margin-top: 32px;
}
@media (max-width: 1080px) {
  #changePw .btn_group {
    margin-top: 12px;
  }
}
#myPage {
  padding-top: 60px;
}
@media (min-width: 1081px) and (max-width: 1365px) {
  #myPage {
    padding: 40px;
  }
}
@media (max-width: 1080px) {
  #myPage {
    background-color: #f0f4f3;
    padding: 72px 24px 32px;
  }
}
#myPage .file_input {
  display: none;
}
#myPage .table_wrap:not(:last-of-type) {
  margin-bottom: 64px;
}
@media (max-width: 1080px) {
  #myPage .table_wrap {
    padding: 28px 16px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
  }
  #myPage .table_wrap:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
#myPage .user_info_wrap.mobile .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#myPage .user_info_wrap.mobile .profile .img_wrap {
  position: relative;
}
#myPage .user_info_wrap.mobile .profile .img_wrap .img_border {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  background-color: #ccc;
  overflow: hidden;
}
#myPage .user_info_wrap.mobile .profile .img_wrap .img_border img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
#myPage .user_info_wrap.mobile .profile .img_wrap .profile_input_btn {
  position: absolute;
  bottom: -4px;
  right: -16px;
  width: 32px;
  height: 32px;
}
#myPage .user_info_wrap.mobile .profile .img_wrap .profile_input_btn img {
  width: 100%;
}
#myPage .user_info_wrap.mobile .profile table {
  border-collapse: collapse;
}
#myPage .user_info_wrap.mobile .profile table thead tr {
  border-bottom: 1px solid #555;
}
#myPage .user_info_wrap.mobile .profile table thead tr th,
#myPage .user_info_wrap.mobile .profile table thead tr td {
  padding-bottom: 12px;
}
#myPage .user_info_wrap.mobile .profile table thead tr th {
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  color: #19c791;
}
#myPage .user_info_wrap.mobile .profile table thead tr td {
  text-align: right;
}
#myPage .user_info_wrap.mobile .profile table thead tr td button {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: inherit;
}
#myPage .user_info_wrap.mobile .profile table thead tr td button.btn_default {
  width: 60px;
  height: 24px;
  color: #fff;
}
#myPage .user_info_wrap.mobile .profile table thead tr td button img {
  width: 16px;
  vertical-align: middle;
}
#myPage .user_info_wrap.mobile .profile table tbody tr:first-of-type th,
#myPage .user_info_wrap.mobile .profile table tbody tr:first-of-type td {
  padding-top: 12px;
}
#myPage .user_info_wrap.mobile .profile table tbody tr th,
#myPage .user_info_wrap.mobile .profile table tbody tr td {
  padding: 8px 0;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: inherit;
}
#myPage .user_info_wrap.mobile .profile table tbody tr th.pw,
#myPage .user_info_wrap.mobile .profile table tbody tr td.pw {
  position: relative;
}
#myPage .user_info_wrap.mobile .profile table tbody tr th.pw img,
#myPage .user_info_wrap.mobile .profile table tbody tr td.pw img {
  position: absolute;
  width: 24px;
  top: 50%;
  right: 8px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
#myPage .user_info_wrap.mobile .profile table tbody tr th input,
#myPage .user_info_wrap.mobile .profile table tbody tr td input {
  height: 28px;
  border-radius: 6px;
}
#myPage .user_info_wrap.mobile .profile table tbody tr th button,
#myPage .user_info_wrap.mobile .profile table tbody tr td button {
  width: 60px;
  height: 28px;
  border-radius: 6px;
}
#myPage .user_info_wrap.mobile .profile table tbody tr th .addr_wrap,
#myPage .user_info_wrap.mobile .profile table tbody tr td .addr_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  row-gap: 6px;
}
#myPage
  .user_info_wrap.mobile
  .profile
  table
  tbody
  tr
  th
  .addr_wrap
  .addr_search_box,
#myPage
  .user_info_wrap.mobile
  .profile
  table
  tbody
  tr
  td
  .addr_wrap
  .addr_search_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  #myPage
    .user_info_wrap.mobile
    .profile
    table
    tbody
    tr
    th
    .addr_wrap
    .addr_search_box,
  #myPage
    .user_info_wrap.mobile
    .profile
    table
    tbody
    tr
    td
    .addr_wrap
    .addr_search_box {
    width: 100%;
  }
}
#myPage .user_info_wrap.mobile .profile table tbody tr th {
  width: 30%;
  font-size: 12px;
  font-weight: 700;
  color: inherit;
}
#myPage .user_info_wrap.desktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 420px;
  margin: 0 -130px 78px;
  padding: 56px 130px 32px;
  background-color: #f0f0f0;
}
#myPage .user_info_wrap.desktop .profile {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 94px;
}
#myPage .user_info_wrap.desktop .profile .img_wrap {
  position: relative;
}
#myPage .user_info_wrap.desktop .profile .img_wrap .img_border {
  width: 254px;
  height: 254px;
  border-radius: 30px;
  overflow: hidden;
}
#myPage .user_info_wrap.desktop .profile .img_wrap .img_border img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
#myPage
  .user_info_wrap.desktop
  .profile
  .img_wrap
  .img_border
  .profile_input_btn {
  position: absolute;
  right: -1%;
  bottom: -1%;
  cursor: pointer;
}
#myPage
  .user_info_wrap.desktop
  .profile
  .img_wrap
  .img_border
  .profile_input_btn
  .ic_camera {
  display: block;
  width: 38px;
}
#myPage .user_info_wrap.desktop .user_info_lf,
#myPage .user_info_wrap.desktop .user_info_rt {
  width: 350px;
}
#myPage .user_info_wrap.desktop .user_info_lf .info_wrap,
#myPage .user_info_wrap.desktop .user_info_rt .info_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}
#myPage .user_info_wrap.desktop .user_info_lf .info_wrap dt,
#myPage .user_info_wrap.desktop .user_info_lf .info_wrap dd,
#myPage .user_info_wrap.desktop .user_info_rt .info_wrap dt,
#myPage .user_info_wrap.desktop .user_info_rt .info_wrap dd {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  line-height: 22px;
}
#myPage .user_info_wrap.desktop .user_info_lf .info_wrap dt.pw,
#myPage .user_info_wrap.desktop .user_info_rt .info_wrap dt.pw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#myPage .user_info_wrap.desktop .user_info_lf .info_wrap dd,
#myPage .user_info_wrap.desktop .user_info_rt .info_wrap dd {
  font-weight: 400;
}
#myPage .user_info_wrap.desktop .user_info_lf {
  height: 100%;
}
#myPage .user_info_wrap.desktop .user_info_lf dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
}
#myPage .user_info_wrap.desktop .user_info_lf dl .info_wrap {
  margin-top: 4px;
}
#myPage .user_info_wrap.desktop .user_info_lf dl .info_wrap dt {
  width: 92px;
  margin-right: 32px;
}
#myPage .user_info_wrap.desktop .user_info_lf dl .info_txt {
  font-size: 15px;
  font-weight: 400;
  color: #191919;
  margin-left: auto;
  margin-bottom: 10px;
  padding-top: 8px;
  line-height: 18px;
  text-align: right;
}
#myPage .user_info_wrap.desktop .user_info_line {
  margin: 0 30px;
  height: 100%;
  border-left: 1px solid #939393;
}
#myPage .user_info_wrap.desktop .user_info_rt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
#myPage .user_info_wrap.desktop .user_info_rt dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap:last-child {
  margin-bottom: 34px;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap.addr {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap dt {
  width: 35%;
  margin-right: 15px;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap dd {
  width: 65%;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap dd .addr_title {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap dd input {
  height: 33px;
}
#myPage .user_info_wrap.desktop .user_info_rt dl .info_wrap dd.addr_container {
  width: 100%;
}
#myPage
  .user_info_wrap.desktop
  .user_info_rt
  dl
  .info_wrap
  dd.addr_container
  input:not(:last-child) {
  margin-bottom: 7px;
}
#myPage
  .user_info_wrap.desktop
  .user_info_rt
  dl
  .info_wrap
  dd.addr_container
  .addr_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 7px;
}
#myPage
  .user_info_wrap.desktop
  .user_info_rt
  dl
  .info_wrap
  dd.addr_container
  .addr_wrap
  .post_input {
  width: 200px;
  margin-right: 17px;
  margin-bottom: 0;
}
#myPage
  .user_info_wrap.desktop
  .user_info_rt
  dl
  .info_wrap
  dd.addr_container
  .addr_wrap
  .search_btn {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  height: 33px;
  background-color: #72d29a;
  line-height: 20px;
}
@media (min-width: 1081px) {
  #myPage .user_work_wrap {
    margin-bottom: 75px;
  }
}
#myPage .user_work_wrap table {
  text-align: left;
  border-collapse: collapse;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table {
    table-layout: fixed;
  }
}
#myPage .user_work_wrap table thead tr {
  border-bottom: 1px solid #555;
  padding-bottom: 20px;
}
#myPage .user_work_wrap table thead tr th,
#myPage .user_work_wrap table thead tr td {
  padding-bottom: 20px;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table thead tr th,
  #myPage .user_work_wrap table thead tr td {
    padding-bottom: 12px;
  }
}
#myPage .user_work_wrap table thead tr th {
  font-size: 24px;
  font-weight: 800;
  color: #19c791;
  width: 16%;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table thead tr th {
    font-size: 14px;
    font-weight: 800;
    color: #19c791;
    width: 28%;
  }
}
#myPage .user_work_wrap table thead tr td {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
#myPage .user_work_wrap table thead tr td.add_btn_wrap {
  text-align: right;
}
#myPage .user_work_wrap table thead tr td.add_btn_wrap button {
  display: inline;
  font-size: 18px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table thead tr td.add_btn_wrap button {
    font-size: 12px;
    font-weight: 700;
    color: inherit;
  }
}
#myPage .user_work_wrap table thead tr td .is_open_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table thead tr td .is_open_wrap {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
#myPage .user_work_wrap table thead tr td .is_open_wrap label {
  margin-left: 30px;
  font-size: 0;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table thead tr td .is_open_wrap label {
    margin-left: 8px;
  }
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr:first-of-type th,
  #myPage .user_work_wrap table tbody tr:first-of-type td {
    padding-top: 12px;
  }
}
#myPage .user_work_wrap table tbody tr th,
#myPage .user_work_wrap table tbody tr td {
  padding: 20px 0;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr th,
  #myPage .user_work_wrap table tbody tr td {
    padding: 8px 0;
  }
}
#myPage .user_work_wrap table tbody tr th {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr th {
    font-size: 12px;
    font-weight: 700;
    color: inherit;
  }
}
#myPage .user_work_wrap table tbody tr td {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr td {
    font-size: 12px;
    font-weight: 400;
    color: inherit;
  }
}
#myPage .user_work_wrap table tbody tr td .certificate_date_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr td .certificate_date_wrap {
    -webkit-column-gap: 6px;
    -moz-column-gap: 6px;
    column-gap: 6px;
  }
}
#myPage .user_work_wrap table tbody tr td .certificate_date_wrap img {
  display: block;
  width: 27px;
}
@media (max-width: 1080px) {
  #myPage .user_work_wrap table tbody tr td .certificate_date_wrap img {
    width: 16px;
  }
}

#academy {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  max-height: 100vh;
  padding: 128px 0 0;
  overflow: hidden;
}
@media (max-width: 1080px) {
  #academy {
    padding-top: 40px;
    max-height: var(--vh);
  }
}
#academy .academy_nav {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 8px;
  height: calc(100vh - 128px);
  width: 160px;
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition:
    width 0.5s,
    padding 0.5s;
  transition:
    width 0.5s,
    padding 0.5s;
  z-index: 9;
}
@media (max-width: 1080px) {
  #academy .academy_nav {
    position: absolute;
    top: 40px;
    width: 56px;
    height: calc(var(--vh) - 40px);
  }
  #academy .academy_nav span {
    display: none;
  }
  #academy .academy_nav ul::-webkit-scrollbar {
    width: 0;
  }
}
#academy .academy_nav.hide {
  width: 64px;
}
@media (max-width: 1080px) {
  #academy .academy_nav.hide {
    padding: 0;
    width: 0;
  }
  #academy .academy_nav.hide ul {
    overflow: hidden;
  }
}
#academy .academy_nav.hide span {
  display: none;
}
#academy .academy_nav.hide .btn_close_nav img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
#academy .academy_nav .btn_close_nav {
  position: absolute;
  left: 100%;
  top: 0;
  width: 36px;
  height: 68px;
  border-bottom-right-radius: 10px;
  background-color: #bcbcbc;
  color: #fff;
}
@media (max-width: 1080px) {
  #academy .academy_nav .btn_close_nav {
    width: 32px;
    height: 50px;
  }
}
#academy .academy_nav .btn_close_nav img {
  width: 28%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
#academy .academy_nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 1080px) {
  #academy .academy_nav ul {
    overflow-y: scroll;
    display: block;
  }
}
#academy .academy_nav ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  row-gap: 12px;
  padding: 8px;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #505050;
  cursor: pointer;
}
@media (max-width: 1080px) {
  #academy .academy_nav ul li {
    height: 40px;
    border-radius: 50px;
  }
  #academy .academy_nav ul li:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
#academy .academy_nav ul li.active {
  color: #19c791;
  background-color: #e0faf2;
}
#academy .academy_nav ul li.etc {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#academy .academy_nav .icon_wrap {
  width: 30px;
  height: 30px;
}
@media (max-width: 1080px) {
  #academy .academy_nav .icon_wrap {
    width: 24px;
    height: 24px;
  }
}
#academy .academy_nav .icon_wrap img {
  width: 100%;
}
#academy .academy_nav span {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
#academy .inner {
  padding: 40px 8%;
  max-width: unset;
  min-height: 100%;
  overflow-y: scroll;
  /* 학원정보상세 */
  /* 선생님 */
}
@media (max-width: 1080px) {
  #academy .inner > header {
    padding: 0;
    border: none;
  }
  #academy .inner > header h2 {
    font-size: 0;
  }
}
#academy .inner > header .btn_group {
  width: 192px;
}
@media (max-width: 1080px) {
  #academy .inner > header .btn_group {
    width: unset;
  }
}
#academy .inner .content_wrap {
  width: 100%;
  margin-top: 44px;
}
@media (max-width: 1080px) {
  #academy .inner .content_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 0;
  }
}
#academy .inner .content_wrap > .btn_group {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 60px 0;
}
@media (max-width: 1080px) {
  #academy .inner .content_wrap > .btn_group {
    margin: 24px 0;
  }
}
#academy .inner .content_wrap .select_list {
  height: auto;
}
#academy .inner .content_wrap .select_list .cell_content_wrap {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 28px;
  -moz-column-gap: 28px;
  column-gap: 28px;
  row-gap: 10px;
  min-height: 120px;
  height: auto;
}
@media (max-width: 1080px) {
  #academy .inner .content_wrap .select_list .cell_content_wrap {
    min-height: unset;
  }
}
#academy .inner .content_wrap .select_list .cell_content_wrap .select_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0 24px;
  min-width: calc(50% - 14px);
  height: 33px;
  border-radius: 10px;
  background-color: #ffcd4b;
}
@media (max-width: 1080px) {
  #academy .inner .content_wrap .select_list .cell_content_wrap .select_item {
    width: 100%;
    padding: 0 12px;
  }
}
#academy .inner .content_wrap .select_list .cell_content_wrap .select_item * {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
#academy
  .inner
  .content_wrap
  .cell_content_wrap
  input:not([type="checkbox"])
  .selectbox_wrap {
  height: 40px;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail {
    background-color: #f0f4f3;
  }
}
#academy .inner.teacher_detail .content_wrap:not(:last-of-type) {
  margin-bottom: 64px;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
#academy .inner.teacher_detail .content_wrap header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 2px solid #555;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap header {
    padding-bottom: 12px;
    border-bottom: 1px solid #555;
  }
}
#academy .inner.teacher_detail .content_wrap header h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  font-size: 24px;
  font-weight: 800;
  color: #191919;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap header h2 {
    font-size: 14px;
  }
}
#academy .inner.teacher_detail .content_wrap header h2 span {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
#academy .inner.teacher_detail .content_wrap header .btn_group {
  width: 192px;
}
#academy .inner.teacher_detail .content_wrap header .btn_group .btn_default {
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.default_info,
  #academy .inner.teacher_detail .content_wrap.salary_authority,
  #academy .inner.teacher_detail .content_wrap .subject,
  #academy .inner.teacher_detail .content_wrap .certificate,
  #academy .inner.teacher_detail .content_wrap .history {
    padding: 28px 16px;
    border-radius: 10px;
    background-color: #fff;
  }
}
#academy .inner.teacher_detail .content_wrap.default_info .default_info_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 36px;
  -webkit-column-gap: 92px;
  -moz-column-gap: 92px;
  column-gap: 92px;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.default_info .default_info_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    row-gap: 36px;
    width: 100%;
  }
}
#academy
  .inner.teacher_detail
  .content_wrap.default_info
  .default_info_wrap
  .profile_img_wrap {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 254px;
  height: 254px;
  border-radius: 30px;
  overflow: hidden;
  background-color: #d9d9d9;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.default_info
    .default_info_wrap
    .profile_img_wrap {
    width: 40vw;
    height: 40vw;
    border-radius: 10%;
  }
}
#academy
  .inner.teacher_detail
  .content_wrap.default_info
  .default_info_wrap
  .profile_img_wrap
  img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
#academy .inner.teacher_detail .content_wrap.default_info .detail * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.default_info .detail * {
    font-size: 14px;
    width: 100%;
  }
}
#academy .inner.teacher_detail .content_wrap.default_info .detail dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#academy
  .inner.teacher_detail
  .content_wrap.default_info
  .detail
  dl:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.default_info
    .detail
    dl:not(:last-child) {
    margin-bottom: 12px;
  }
}
#academy .inner.teacher_detail .content_wrap.default_info .detail dl dt {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 160px;
  font-weight: 700;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.default_info .detail dl dt {
    width: 120px;
  }
}
#academy .inner.teacher_detail .content_wrap.default_info .detail dl dd + dt {
  margin-left: 32px;
}
#academy
  .inner.teacher_detail
  .content_wrap.default_info
  .detail
  dl
  dd
  .class_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  row-gap: 12px;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.default_info
    .detail
    dl
    dd
    .class_list {
    -webkit-column-gap: 4px;
    -moz-column-gap: 4px;
    column-gap: 4px;
    row-gap: 4px;
  }
}
#academy
  .inner.teacher_detail
  .content_wrap.default_info
  .detail
  dl
  dd
  .class_list
  li {
  padding: 6px 24px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #19c791;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.default_info
    .detail
    dl
    dd
    .class_list
    li {
    width: calc(50% - 4px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    padding: 6px;
    text-align: center;
  }
}
#academy .inner.teacher_detail .content_wrap.salary_authority header {
  width: 100%;
}
#academy .inner.teacher_detail .content_wrap.salary_authority header h2 img {
  width: 20px;
}
#academy .inner.teacher_detail .content_wrap.salary_authority header h2 span {
  font-size: 12px;
}
#academy .inner.teacher_detail .content_wrap.salary_authority .detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 28px;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.salary_authority .detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    row-gap: 12px;
    width: 100%;
  }
}
#academy .inner.teacher_detail .content_wrap.salary_authority dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#academy .inner.teacher_detail .content_wrap.salary_authority dl * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.salary_authority dl * {
    font-size: 12px;
  }
}
#academy .inner.teacher_detail .content_wrap.salary_authority dl dt {
  width: 92px;
  font-weight: 700;
}
#academy .inner.teacher_detail .content_wrap.ability header h2 {
  color: #19c791;
}
#academy .inner.teacher_detail .content_wrap.ability table {
  text-align: left;
}
#academy .inner.teacher_detail .content_wrap.ability table * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.ability table * {
    font-size: 12px;
  }
}
#academy .inner.teacher_detail .content_wrap.ability table th {
  font-weight: 700;
}
#academy .inner.teacher_detail .content_wrap.ability table th,
#academy .inner.teacher_detail .content_wrap.ability table td {
  padding: 24px 0;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.ability table th,
  #academy .inner.teacher_detail .content_wrap.ability table td {
    padding: 8px 0;
  }
}
#academy
  .inner.teacher_detail
  .content_wrap.ability
  table
  th
  .certificate_date_wrap,
#academy
  .inner.teacher_detail
  .content_wrap.ability
  table
  td
  .certificate_date_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
}
#academy
  .inner.teacher_detail
  .content_wrap.ability
  table
  th
  .certificate_date_wrap
  img,
#academy
  .inner.teacher_detail
  .content_wrap.ability
  table
  td
  .certificate_date_wrap
  img {
  display: block;
  width: 27px;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.ability
    table
    th
    .certificate_date_wrap
    img,
  #academy
    .inner.teacher_detail
    .content_wrap.ability
    table
    td
    .certificate_date_wrap
    img {
    width: 16px;
  }
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.ability
    table
    tr:first-of-type
    th {
    padding-top: 12px;
  }
}
#academy .inner.teacher_detail .content_wrap.ability .hidden_info {
  margin-top: 64px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
}
@media (max-width: 1080px) {
  #academy .inner.teacher_detail .content_wrap.ability .hidden_info {
    margin-top: 24px;
    font-size: 12px;
  }
}
#academy
  .inner.teacher_detail
  .content_wrap.ability
  section:not(:last-of-type) {
  margin-bottom: 72px;
}
@media (max-width: 1080px) {
  #academy
    .inner.teacher_detail
    .content_wrap.ability
    section:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
#academy .inner.classroom_edit .classroom_alert {
  margin-bottom: 12px;
  text-align: right;
  color: #ff2222;
}
@media (max-width: 1080px) {
  #academy .inner.classroom_edit .classroom_alert {
    font-size: 10px;
  }
}
@media (max-width: 1080px) {
  #academy .inner.shuttle_add .content_wrap .desc,
  #academy .inner.schedule_add .content_wrap .desc {
    font-size: 12px;
  }
}
@media (max-width: 1080px) {
  #academy .inner.shuttle_add .content_wrap table th,
  #academy .inner.schedule_add .content_wrap table th {
    width: 26%;
  }
}
#academy .inner.shuttle_add .content_wrap table .green3,
#academy .inner.shuttle_add .content_wrap table .del,
#academy .inner.schedule_add .content_wrap table .green3,
#academy .inner.schedule_add .content_wrap table .del {
  width: 112px;
}
#academy .inner.shuttle_add .content_wrap table .mini,
#academy .inner.schedule_add .content_wrap table .mini {
  width: 66px;
}
#academy .inner.shuttle_add .content_wrap table col:last-of-type,
#academy .inner.schedule_add .content_wrap table col:last-of-type {
  width: 112px;
}
#academy .inner.shuttle_add .content_wrap table td.shuttle_type .selectbox_wrap,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.shuttle_type
  .selectbox_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#academy .inner.shuttle_add .content_wrap table td.station_list,
#academy .inner.schedule_add .content_wrap table td.station_list {
  padding: 0;
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td.station_list
    .cell_content_wrap.date,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td.station_list
    .cell_content_wrap.date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden {
  z-index: 2;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide {
  padding: 12px;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap {
  padding: 12px 12px 32px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 10px #ccc;
  box-shadow: 0px 0px 10px #ccc;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap
  input,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap
  input {
  height: 28px;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination {
  bottom: 24px;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination
  .swiper-pagination-bullet-active,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #19c791;
}
#academy .inner.shuttle_add .content_wrap table td.station_list .detail,
#academy .inner.schedule_add .content_wrap table td.station_list .detail {
  table-layout: fixed;
  text-align: left;
}
#academy .inner.shuttle_add .content_wrap table td.station_list .detail tr,
#academy .inner.shuttle_add .content_wrap table td.station_list .detail td,
#academy .inner.shuttle_add .content_wrap table td.station_list .detail th,
#academy .inner.schedule_add .content_wrap table td.station_list .detail tr,
#academy .inner.schedule_add .content_wrap table td.station_list .detail td,
#academy .inner.schedule_add .content_wrap table td.station_list .detail th {
  border: none;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .detail
  tr.shuttle_option
  td,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .detail
  tr.shuttle_option
  td {
  padding-bottom: 24px;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .detail
  tr.station_item
  input.wrong,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .detail
  tr.station_item
  input.wrong {
  background-color: rgba(255, 34, 34, 0.2);
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .detail
  tr:nth-of-type(2),
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .detail
  tr:nth-of-type(2) {
  border-top: 1.5px solid #b4b4b4;
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td.station_list
    .detail
    tr:nth-of-type(2),
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td.station_list
    .detail
    tr:nth-of-type(2) {
    border: none;
  }
}
#academy .inner.shuttle_add .content_wrap table td.station_list .detail th,
#academy .inner.schedule_add .content_wrap table td.station_list .detail th {
  background-color: #fff;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex {
  padding-bottom: 12px;
  border-bottom: 1px solid #b4b4b4;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex
  button,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex
  button {
  width: unset;
  padding: 0 16px;
}
@media (max-width: 1080px) {
  #academy .inner.shuttle_add .content_wrap table td .cell_content_wrap.date,
  #academy .inner.schedule_add .content_wrap table td .cell_content_wrap.date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap.date
    .indefinite,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap.date
    .indefinite {
    max-width: 110px;
  }
}
#academy .inner.shuttle_add .content_wrap table td .cell_content_wrap .unit,
#academy .inner.schedule_add .content_wrap table td .cell_content_wrap .unit {
  font-size: 14px;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .add_schedule_time,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .add_schedule_time {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  font-size: 18px;
  background-color: #f0f0f0;
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .indefinite,
#academy .inner.shuttle_add .content_wrap table td .cell_content_wrap .teacher,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .indefinite,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .teacher {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  width: 208px;
  line-height: 50px;
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite,
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher {
    max-width: 80%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite
    ~ .btn_group,
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher
    ~ .btn_group,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite
    ~ .btn_group,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher
    ~ .btn_group {
    width: unset;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite
    ~ .btn_group
    button,
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher
    ~ .btn_group
    button,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .indefinite
    ~ .btn_group
    button,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .teacher
    ~ .btn_group
    button {
    width: unset;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .react-datepicker-wrapper,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .react-datepicker-wrapper {
    max-width: 110px;
  }
}
#academy
  .inner.shuttle_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .operating_days,
#academy
  .inner.schedule_add
  .content_wrap
  table
  td
  .cell_content_wrap
  .operating_days {
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
@media (max-width: 1080px) {
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days {
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    label,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    label {
    padding: 8px;
    border-radius: 4px;
    background-color: #f0f0f0;
  }
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    input,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    input {
    display: none;
  }
  #academy
    .inner.shuttle_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    input:checked
    ~ label,
  #academy
    .inner.schedule_add
    .content_wrap
    table
    td
    .cell_content_wrap
    .operating_days
    li
    input:checked
    ~ label {
    background-color: #19c791;
    color: #fff;
  }
}
#academy .inner.evaluation_detail .content_wrap {
  margin-top: 44px;
}
@media (max-width: 1080px) {
  #academy .inner.evaluation_detail .content_wrap {
    margin-top: 24px;
  }
}
#academy .inner.evaluation_detail .content_wrap > .btn_group {
  margin: 52px 0;
}
@media (max-width: 1080px) {
  #academy .inner.evaluation_detail .content_wrap > .btn_group {
    margin: 24px 0 0;
  }
}
@media (max-width: 1080px) {
  #academy .inner.evaluation_detail .content_wrap table th {
    width: 26%;
  }
}
#academy
  .inner.evaluation_detail
  .content_wrap
  table
  td
  .cell_content_wrap
  .desc {
  margin-left: 16px;
}
@media (max-width: 1080px) {
  #academy
    .inner.evaluation_detail
    .content_wrap
    table
    td
    .cell_content_wrap
    .desc {
    margin-left: 0px;
  }
}
#academy
  .inner.evaluation_detail
  .content_wrap
  table
  td
  .cell_content_wrap
  strong {
  font-size: 20px;
  font-weight: 700;
  color: #197edc;
}
@media (max-width: 1080px) {
  #academy
    .inner.evaluation_detail
    .content_wrap
    table
    td
    .cell_content_wrap
    strong {
    font-size: 12px;
  }
}
#academy
  .inner.evaluation_detail
  .content_wrap
  table
  td
  .cell_content_wrap
  strong.down {
  color: #f22;
}
#academy .inner.info_wrap {
  width: 100%;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap {
    background-color: #f0f4f3;
  }
}
#academy .inner.info_wrap .info_section {
  margin-top: 0;
  margin-bottom: 60px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .info_section {
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 10px;
    background-color: #fff;
  }
}
#academy .inner.info_wrap .info_section header h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#academy .inner.info_wrap .info_section header h2 img {
  position: relative;
  top: 2px;
  margin-left: 4px;
  width: 27px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .info_section header h2 img {
    margin-left: 8px;
    width: 20px;
  }
}
#academy .inner.info_wrap .basic_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 36px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    row-gap: 36px;
  }
}
#academy .inner.info_wrap .basic_info .profile_container {
  position: relative;
}
#academy .inner.info_wrap .basic_info .profile_container .profile_wrap {
  width: 254px;
  height: 254px;
  overflow: hidden;
  border-radius: 30px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .profile_container .profile_wrap {
    width: 40vw;
    height: 40vw;
    border-radius: 10%;
  }
}
#academy .inner.info_wrap .basic_info .profile_container .profile_wrap img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center center;
  object-position: center center;
}
#academy .inner.info_wrap .basic_info .profile_container .file_attach_wrap {
  position: absolute;
  right: -8px;
  bottom: -8px;
}
#academy
  .inner.info_wrap
  .basic_info
  .profile_container
  .file_attach_wrap
  .file_icon {
  display: block;
  width: 38px;
  height: 38px;
}
#academy
  .inner.info_wrap
  .basic_info
  .profile_container
  .file_attach_wrap
  .file_label {
  display: inline-block;
  cursor: pointer;
}
#academy
  .inner.info_wrap
  .basic_info
  .profile_container
  .file_attach_wrap
  .file_input {
  display: none;
}
#academy .inner.info_wrap .basic_info .academy_basic_info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 94px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .academy_basic_info {
    margin: 0;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 1080px) {
  #academy
    .inner.info_wrap
    .basic_info
    .academy_basic_info
    dl:not(:last-child) {
    margin-bottom: 20px;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dt,
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd {
  font-size: 20px;
  font-weight: 400;
  color: inherit;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .academy_basic_info dl dt,
  #academy .inner.info_wrap .basic_info .academy_basic_info dl dd {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dt {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 30%;
  max-width: 180px;
  font-weight: 700;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .academy_basic_info dl dt {
    width: 120px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd input {
  height: 33px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .academy_basic_info dl dd input {
    width: 100% !important;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd input.mobile {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd span {
  position: relative;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #191919;
  display: inline-block;
  margin-top: 10px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .basic_info .academy_basic_info dl dd span {
    font-size: 12px;
  }
}
#academy .inner.info_wrap .basic_info .academy_basic_info dl dd .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 4px;
  -moz-column-gap: 4px;
  column-gap: 4px;
}
@media (max-width: 1080px) {
  #academy
    .inner.info_wrap
    .basic_info
    .academy_basic_info
    dl
    .subject_list
    li {
    width: calc(50% - 4px);
  }
}
#academy .inner.info_wrap .academy_picture_wrap header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  header
  .file_attach_wrap
  .file_icon {
  display: inline-block;
  padding: 20px;
  background-color: #19c791;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  header
  .file_attach_wrap
  .file_label {
  display: inline-block;
  cursor: pointer;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  header
  .file_attach_wrap
  .file_label
  .file_txt {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #19c791;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  header
  .file_attach_wrap
  .file_input {
  display: none;
}
#academy .inner.info_wrap .academy_picture_wrap .img_grid_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
#academy .inner.info_wrap .academy_picture_wrap .img_grid_list .img_grid_item,
#academy .inner.info_wrap .academy_picture_wrap .img_grid_list .add_img_wrap {
  position: relative;
  margin: 2% 2%;
  width: 29.3333333333%;
  height: 13vw;
  background-color: #d6d6d6;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .academy_picture_wrap .img_grid_list .img_grid_item,
  #academy .inner.info_wrap .academy_picture_wrap .img_grid_list .add_img_wrap {
    width: 46%;
    height: 35.77vw;
  }
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .img_grid_item
  .img_wrap,
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap
  .img_wrap {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .img_grid_item
  .img_wrap
  img,
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap
  .img_wrap
  img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .img_grid_item
  .close_btn,
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap
  .close_btn {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 8px 12px;
  color: #fff;
  border-radius: 50%;
  background-color: #939393;
}
#academy .inner.info_wrap .academy_picture_wrap .img_grid_list .add_img_wrap {
  position: relative;
  background-color: #f0f0f0;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap::before,
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap::after {
  display: inline-block;
  position: absolute;
  background-color: #b4b4b4;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap::before {
  width: 40px;
  height: 4px;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap::after {
  width: 4px;
  height: 40px;
}
#academy
  .inner.info_wrap
  .academy_picture_wrap
  .img_grid_list
  .add_img_wrap
  label {
  display: inline-block;
  width: 100%;
  height: 100%;
}
#academy .inner.info_wrap .config_wrap .config_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 30px;
}
#academy .inner.info_wrap .config_wrap .config_list .config_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 28px;
  height: 108px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .config_wrap .config_list .config_item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    row-gap: 12px;
    height: auto;
  }
}
#academy .inner.info_wrap .config_wrap .config_list .config_item.class {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}
#academy .inner.info_wrap .config_wrap .config_list .config_item.class .detail {
  width: 64px;
}
@media (max-width: 1080px) {
  #academy
    .inner.info_wrap
    .config_wrap
    .config_list
    .config_item.class
    .detail {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    row-gap: 4px;
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#academy .inner.info_wrap .config_wrap .config_list .config_item img {
  display: block;
  width: 108px;
  height: 108px;
  margin-right: 16px;
  border-radius: 10px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .config_wrap .config_list .config_item img {
    margin: 0;
    width: 64px;
    height: 64px;
  }
}
#academy .inner.info_wrap .config_wrap .config_list .config_item .detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
  row-gap: 16px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .config_wrap .config_list .config_item .detail {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    row-gap: 4px;
    width: 40px;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
  }
}
#academy
  .inner.info_wrap
  .config_wrap
  .config_list
  .config_item
  .detail
  strong {
  font-weight: 700;
}
#academy .inner.info_wrap .config_wrap .config_list .config_item .subject {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
  row-gap: 8px;
  margin-left: 16px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .config_wrap .config_list .config_item .subject {
    margin: 0;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
  }
}
#academy .inner.info_wrap .config_wrap .config_list .config_item .subject span {
  width: 117px;
  height: 32px;
  padding: 6px 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #19c791;
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
#academy
  .inner.info_wrap
  .config_wrap
  .config_list
  .config_item
  .subject
  span
  p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
}
@media (max-width: 1080px) {
  #academy
    .inner.info_wrap
    .config_wrap
    .config_list
    .config_item
    .subject
    span {
    width: calc(33% - 4px);
    font-size: 14px;
  }
}
#academy .inner.info_wrap .submit_wrap {
  padding: 0;
  width: 100%;
  background-color: transparent;
}
#academy .inner.info_wrap .submit_wrap p {
  margin-top: 20px;
}
@media (max-width: 1080px) {
  #academy .inner.info_wrap .submit_wrap p {
    font-size: 14px;
  }
}
#academy .inner .teacher_wrap table .go_detail {
  cursor: pointer;
}

@media (max-width: 1080px) {
  #header.show_nav ~ .container .academy_nav {
    top: 90px;
    height: calc(var(--vh) - 90px);
  }
}

#student > .btn_group {
  position: fixed;
  left: 0px;
  bottom: 0;
  width: 100vw;
}
#student > .btn_group button {
  border-radius: 0;
}
#student .inner > .btn_group {
  margin: 56px 0;
}
@media (max-width: 1080px) {
  #student .inner > .btn_group {
    margin-bottom: 0;
  }
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap {
    margin: 0;
  }
}
#student .inner .page_content_wrap.select_class_list table,
#student .inner .page_content_wrap.studnet_info table {
  margin-top: 44px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap.select_class_list table,
  #student .inner .page_content_wrap.studnet_info table {
    margin-top: 24px;
  }
}
#student .inner .page_content_wrap > header {
  padding-bottom: 20px;
  border-bottom: 2px solid #555;
}
#student .inner .page_content_wrap > header h2 {
  font-size: 24px;
  font-weight: 800;
  color: #191919;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap > header h2 {
    font-size: 14px;
  }
}
#student .inner .page_content_wrap > header h2 span {
  font-size: 20px;
  font-weight: 800;
  color: #51c581;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap > header h2 span {
    font-size: 14px;
  }
}
#student .inner .page_content_wrap > header .btn_group {
  margin-left: 24px;
  width: 192px;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap > header .btn_group {
    margin: 12px 0 0;
    width: unset;
  }
}
#student .inner .page_content_wrap > header .btn_group .btn_default {
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap > header .btn_group .btn_default {
    padding: 0 16px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }
}
#student .inner .page_content_wrap .empty_data {
  margin: 40px 0;
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  text-align: center;
  line-height: 30px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .empty_data {
    margin: 24px 0;
    font-size: 12px;
  }
}
#student .inner .page_content_wrap table {
  margin-top: 44px;
  text-align: center;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap table {
    margin-top: 24px;
  }
}
#student
  .inner
  .page_content_wrap
  table
  input:not([type="checkbox"]):not([type="radio"]) {
  height: 40px;
}
#student .inner .page_content_wrap table.border_none td {
  text-align: center;
}
#student .inner .page_content_wrap table .class_info,
#student .inner .page_content_wrap table .shuttle_info {
  border: none;
}
#student .inner .page_content_wrap table .class_info th:not(:first-of-type),
#student .inner .page_content_wrap table .shuttle_info th:not(:first-of-type) {
  background-color: #fff;
  border: none;
  border-top: 1.5px solid #b4b4b4;
}
#student .inner .page_content_wrap table .class_info td,
#student .inner .page_content_wrap table .shuttle_info td {
  text-align: center !important;
  border: none;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap table th .selectbox_wrap {
    width: 100%;
    height: 24px;
    border-radius: 6px;
  }
}
#student .inner .page_content_wrap table td {
  text-align: left;
}
#student .inner .page_content_wrap table td .cell_content_wrap button {
  width: 80px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap table td .cell_content_wrap.address {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 8px;
  }
  #student
    .inner
    .page_content_wrap
    table
    td
    .cell_content_wrap.address
    .postcode_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    -webkit-column-gap: 12px;
    -moz-column-gap: 12px;
    column-gap: 12px;
  }
}
#student .inner .page_content_wrap table td.station_list {
  padding: 0;
  border: none;
}
@media (max-width: 1080px) {
  #student
    .inner
    .page_content_wrap
    table
    td.station_list
    .cell_content_wrap.date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    row-gap: 12px;
  }
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden {
  z-index: 0;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide {
  padding: 12px;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap {
  padding: 12px 12px 32px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 10px #ccc;
  box-shadow: 0px 0px 10px #ccc;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-wrapper
  .swiper-slide
  .table_wrap
  input {
  height: 28px;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination {
  bottom: 24px;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .swiper-backface-hidden
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #19c791;
}
#student .inner .page_content_wrap table td.station_list .detail {
  table-layout: fixed;
  text-align: left;
  border: none;
  margin: 0;
}
#student .inner .page_content_wrap table td.station_list .detail tr,
#student .inner .page_content_wrap table td.station_list .detail td,
#student .inner .page_content_wrap table td.station_list .detail th {
  border: none;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .detail
  tr.shuttle_option
  td {
  padding-bottom: 24px;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .detail
  tr.station_item
  input.wrong {
  background-color: rgba(255, 34, 34, 0.2);
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .detail
  tr:nth-of-type(2) {
  border-top: 1.5px solid #b4b4b4;
}
@media (max-width: 1080px) {
  #student
    .inner
    .page_content_wrap
    table
    td.station_list
    .detail
    tr:nth-of-type(2) {
    border: none;
  }
}
#student .inner .page_content_wrap table td.station_list .detail th {
  background-color: #fff;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex {
  padding-bottom: 12px;
  border-bottom: 1px solid #b4b4b4;
}
#student
  .inner
  .page_content_wrap
  table
  td.station_list
  .detail
  th.title
  .flex
  button {
  width: unset;
  padding: 0 16px;
}
#student .inner .page_content_wrap table .student_state .selectbox_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 40px;
}
#student .inner .page_content_wrap table .react-datepicker-wrapper {
  width: 100%;
}
#student .inner .page_content_wrap table .capacity_full * {
  color: #939393;
}
#student .inner .page_content_wrap .attendance,
#student .inner .page_content_wrap .homework {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .attendance,
  #student .inner .page_content_wrap .homework {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#student .inner .page_content_wrap .attendance .graph_wrap,
#student .inner .page_content_wrap .homework .graph_wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#student .inner .page_content_wrap .attendance .graph_wrap .graph,
#student .inner .page_content_wrap .homework .graph_wrap .graph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#student .inner .page_content_wrap .attendance .graph_wrap ul,
#student .inner .page_content_wrap .homework .graph_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  margin-top: 60px;
  width: 100%;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .attendance .graph_wrap ul,
  #student .inner .page_content_wrap .homework .graph_wrap ul {
    margin-top: 24px;
  }
}
#student .inner .page_content_wrap .attendance .graph_wrap ul li,
#student .inner .page_content_wrap .homework .graph_wrap ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .attendance .graph_wrap ul li,
  #student .inner .page_content_wrap .homework .graph_wrap ul li {
    font-size: 12px;
  }
}
#student .inner .page_content_wrap .attendance .graph_wrap ul li span,
#student .inner .page_content_wrap .homework .graph_wrap ul li span {
  display: inline-block;
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .attendance .graph_wrap ul li span,
  #student .inner .page_content_wrap .homework .graph_wrap ul li span {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
}
#student
  .inner
  .page_content_wrap
  .attendance
  .graph_wrap
  ul
  li:nth-of-type(1)
  span,
#student
  .inner
  .page_content_wrap
  .homework
  .graph_wrap
  ul
  li:nth-of-type(1)
  span {
  background-color: #19c791;
}
#student
  .inner
  .page_content_wrap
  .attendance
  .graph_wrap
  ul
  li:nth-of-type(2)
  span,
#student
  .inner
  .page_content_wrap
  .homework
  .graph_wrap
  ul
  li:nth-of-type(2)
  span {
  background-color: #ffac4b;
}
#student
  .inner
  .page_content_wrap
  .attendance
  .graph_wrap
  ul
  li:nth-of-type(3)
  span,
#student
  .inner
  .page_content_wrap
  .homework
  .graph_wrap
  ul
  li:nth-of-type(3)
  span {
  background-color: #b7b7b7;
}
#student .inner .page_content_wrap .attendance table,
#student .inner .page_content_wrap .homework table {
  width: 728px;
}
@media (max-width: 1080px) {
  #student .inner .page_content_wrap .attendance table,
  #student .inner .page_content_wrap .homework table {
    width: 100%;
  }
}
#student .inner .page_content_wrap .attendance table th,
#student .inner .page_content_wrap .homework table th {
  width: unset;
}

#home .board_wrap:last-child {
  margin-top: 48px;
}
@media (max-width: 1080px) {
  #home .board_wrap:last-child {
    margin-top: 24px;
  }
}
#home .board_wrap > header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1080px) {
  #home .board_wrap > header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
#home .board_wrap > header h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  min-width: 160px;
  line-height: 34px;
}
#home .board_wrap > header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
  margin-left: 20px;
}
@media (max-width: 1080px) {
  #home .board_wrap > header ul {
    width: 100%;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-left: 0px;
  }
}
#home .board_wrap > header ul * {
  font-size: 20px;
  font-weight: 400;
  color: #191919;
}
@media (max-width: 1080px) {
  #home .board_wrap > header ul * {
    font-size: 12px;
  }
}
#home .board_wrap > header ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 28px;
  cursor: pointer;
}
#home .board_wrap > header ul li.refresh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#home .board_wrap > header ul li.refresh img {
  margin-left: 12px;
}
@media (max-width: 1080px) {
  #home .board_wrap > header ul li.refresh img {
    width: 12px;
  }
}
#home .board_wrap > header ul li img {
  margin-right: 8px;
}
@media (max-width: 1080px) {
  #home .board_wrap > header ul li img {
    width: 12px;
  }
}
#home .board_wrap table tbody tr {
  cursor: default;
}
#home .board_wrap table tbody tr.my_data {
  background-color: #d0eed9;
}
#home .board_wrap table tbody tr.bus_location.desktop .bus_location_wrap ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0 40px 40px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  overflow-x: auto;
}
#home .board_wrap table tbody tr.bus_location.desktop .bus_location_wrap ul li {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 68px;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li
  p {
  position: absolute;
  left: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  margin-top: 80px;
  cursor: pointer;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li
  img {
  position: absolute;
  top: 50%;
  left: 0%;
  width: 56px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li::after {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  height: 6px;
  background-color: #b4b4b4;
  content: "";
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li:last-of-type {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li:last-of-type::after {
  display: none;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li:nth-child(odd)::before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border: 6px solid #b4b4b4;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
  cursor: pointer;
  content: "";
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li.pass::after {
  background-color: #ffcd4b;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.desktop
  .bus_location_wrap
  ul
  li.pass::before {
  border-color: #ffcd4b;
}
#home .board_wrap table tbody tr.bus_location.mobile .bus_location_wrap ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 12px 0;
  width: 100%;
  height: 140px;
  overflow-y: auto;
  border-top: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
#home .board_wrap table tbody tr.bus_location.mobile .bus_location_wrap ul li {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 28px;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li
  .bg {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  height: 56px;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li:nth-child(odd):not(:last-child)
  .bg {
  border-bottom: 1px solid #e3e3e3;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li:nth-child(odd)
  .bg.current {
  background-color: #fffaed;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li
  p {
  position: absolute;
  top: 50%;
  left: 80px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 1;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li
  img {
  position: absolute;
  top: 50%;
  left: 40px;
  width: 32px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li::after {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 40px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 2px;
  height: 100%;
  background-color: #b4b4b4;
  content: "";
  z-index: 1;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li:last-of-type {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li:last-of-type::after {
  display: none;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li:nth-child(odd)::before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 40px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 2px solid #b4b4b4;
  background-color: #fff;
  content: "";
  z-index: 2;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li.pass::after {
  background-color: #ffcd4b;
}
#home
  .board_wrap
  table
  tbody
  tr.bus_location.mobile
  .bus_location_wrap
  ul
  li.pass::before {
  border-color: #ffcd4b;
}
#home .board_wrap table tbody td {
  padding: 24px 12px;
}
@media (max-width: 1080px) {
  #home .board_wrap table tbody td {
    padding: 12px 6px;
  }
}

@media (max-width: 1080px) {
  #messenger {
    padding-bottom: 0;
  }
  #messenger header {
    border: none;
  }
  #messenger header h2 {
    opacity: 0;
  }
  #messenger .inner {
    padding-bottom: 0;
  }
  #messenger .inner .page_content_wrap {
    margin-top: 0 !important;
  }
}

@media (max-width: 1080px) {
  #material header {
    border: none;
  }
  #material header h2 {
    opacity: 0;
  }
}
@media (max-width: 1080px) {
  #material .page_content_wrap {
    margin-top: 0 !important;
  }
}
#material .page_content_wrap .cell_content_wrap {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
#material .page_content_wrap .cell_content_wrap label {
  line-height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media (max-width: 1080px) {
  #notification header {
    border: none;
  }
  #notification header h2 {
    opacity: 0;
  }
}
@media (max-width: 1080px) {
  #notification .page_content_wrap {
    margin-top: 0 !important;
  }
}

@media (max-width: 1080px) {
  #homework header {
    border: none;
  }
  #homework header h2 {
    opacity: 0;
  }
}
@media (max-width: 1080px) {
  #homework .page_content_wrap {
    margin-top: 0 !important;
  }
}
#homework .mobile.reward .cell_content_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
